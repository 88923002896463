import { computed, observable } from "mobx";
import { create, persist } from "mobx-persist";
import moment from "moment";
import * as queryString from "query-string";
import { BANKING_REFRESHING_CONNECTION_DATE } from "../config";
import { GLOBAL_FEATURES } from "../features";
import { t } from "../i18n/util";
import { API } from "../network/API";
import {
    BankConnection,
    CompanyUsersToRelease,
    GetBankConnectionsResponse,
    PermissionsInvitationResultResponse,
    TPAEmployeePublicUserInfo,
    UserCompanySettings,
} from "../network/APITypes";
import { ICompany, Module } from "../types/models";
import { addStoreToWindow, debug } from "../util/debug";
import { getFullName } from "../util/user";
import { authStore } from "./AuthStore";
import { CompanyStore } from "./CompanyStore";
import { generalStore } from "./GeneralStore";

const emptyResponsible: TPAEmployeePublicUserInfo = {
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    gender: "notSpecified",
    profile_picture_url: undefined,
};

interface IResponsibleUsers {
    accounting: TPAEmployeePublicUserInfo;
    hr: TPAEmployeePublicUserInfo;
}

class CompaniesStore {
    @observable isRehydrated = false;

    @observable numCompanies = -1;
    @persist @observable _selectedCompanyId?: string;
    @observable selectedCompany?: ICompany;
    @observable waitingUsers: CompanyUsersToRelease[] | null = null;
    @observable invitationResults: PermissionsInvitationResultResponse = [];
    @observable mainResponsible: IResponsibleUsers = { accounting: emptyResponsible, hr: emptyResponsible };
    @observable totalUnreadNotifications = 0;
    @observable userCompanySettings?: UserCompanySettings;
    @observable forceReloadOptionalSideBarItems = false;
    @observable bankConnections: GetBankConnectionsResponse = { connections: [] };

    /** Whether the current company's terms have been accepted */
    @computed get termsAccepted() {
        if (!this.selectedCompanyStore) {
            return false;
        }
        return (
            this.selectedCompanyStore.company.id === this._selectedCompanyId &&
            !!this.selectedCompanyStore.company.companyTerms?.acceptedAt
        );
    }
    /** A user can access a company if the terms have been accepted or they are an advisors/accountant/hr of that company */
    @computed get canAccessCompany() {
        return this.termsAccepted || !!this.selectedCompanyStore?.permissions.hasManagingTPARole;
    }

    @observable selectedCompanyStore?: CompanyStore;

    initialized = false;

    async init() {
        if (!this.initialized) {
            // load the users first because `loadCompanies` will automatically select the company if only 1 is returned
            // which requires the waiting users to be loaded to display the "waiting users" dialog.
            await this.loadWaitingUsers();

            await this.loadCompanies();

            // After rehydration
            if (this.selectedCompanyId && this.selectedCompany?.id !== this.selectedCompanyId) {
                try {
                    await this.setSelectedCompanyId(this.selectedCompanyId, true);
                } catch (err) {
                    // Local storage had company ID saved which is not
                    // valid anymore. Could happen e.g. if permissions are lost
                    this.setSelectedCompanyId(undefined, true);
                }
            }
        }
    }

    clearSelection() {
        this.setSelectedCompanyId(undefined, true);
    }

    wipe() {
        this.initialized = false;
        this.clearSelection();
        this.mainResponsible = { accounting: emptyResponsible, hr: emptyResponsible };
        this.waitingUsers = [];
        this.invitationResults = [];
        this.numCompanies = -1;
    }

    isCEE(company?: ICompany) {
        if (!company) {
            company = this.selectedCompany;
        }
        return !(company?.countryCode === "AT");
    }

    private async loadCompanies() {
        const response = await API.getCompanies();
        if (response?.companies) {
            this.numCompanies = response.total;

            // Set initial selection if only one company
            if (response.total === 1) {
                await this.setSelectedCompanyId(response.companies[0].id);
            }
        }

        // Only set initialized to true if load didn't throw
        this.initialized = true;
    }

    private clearSelectionData = () => {
        generalStore.showUserReleaseInfoAdminDialog = false;
        generalStore.showUserReleaseInfoDialog = false;
        this.invitationResults = [];
        this.mainResponsible = { accounting: emptyResponsible, hr: emptyResponsible };
        this.selectedCompany = undefined;
        this.selectedCompanyStore?.dispose();
        this.selectedCompanyStore = undefined;
    };

    async setSelectedCompanyId(id: string | undefined, force = false) {
        if (id !== this.selectedCompanyId || force) {
            debug.log("### select company", id);
            this._selectedCompanyId = id;

            if (id) {
                await this.loadCompany(id);

                if (this.canAccessCompany) {
                    await this.loadBankConnections();
                    await this.loadUserCompanySettings();
                    await this.loadMainResponsibleUsers();

                    // Start polling badges
                    await this.selectedCompanyStore?.startPollingBadges({
                        showOpenEmployeeDocumentReleasesDialog: true,
                    });

                    // Display the release info dialog if possible
                    if (this.selectedCompanyStore?.isSuperAdmin && this.getWaitingUsers(id).length > 0) {
                        generalStore.showUserReleaseInfoAdminDialog = true;
                    }
                    await this.loadInvitationResults(true);
                }
            } else {
                this.clearSelectionData();
            }
        }
    }

    async reloadCompany(noCache?: boolean) {
        if (this.selectedCompanyId) {
            await this.loadCompany(this.selectedCompanyId, noCache);
        }
    }

    async loadCompany(id: string, noCache?: boolean) {
        // First load permissions, since any subsequent call might use them
        await authStore.loadPermissions(id);
        const permissions = authStore.permissions;

        const featureFlags = await API.getCompanyFeatureFlags(id);

        this.selectedCompany = await API.getCompany(id, noCache);
        this.selectedCompanyStore?.dispose();
        this.selectedCompanyStore = new CompanyStore(this.selectedCompany, permissions, featureFlags.featureFlags);
    }

    async loadBankConnections() {
        const companyStore = this.selectedCompanyStore;
        if (GLOBAL_FEATURES.bankConnections && companyStore?.canReadBanking()) {
            try {
                this.bankConnections = await API.getBankConnections(companyStore.id);
            } catch (error) {
                generalStore.setError(t("error.loadBankConnections"), error);
            }
        }
    }

    async loadUserCompanySettings() {
        if (!this.selectedCompanyId) {
            return;
        }

        try {
            this.userCompanySettings = await API.getUserCompanySettings(this.selectedCompanyId);
        } catch (error) {
            generalStore.setError(t("error.loadUserCompanySettings"), error);
        }
    }

    async loadMainResponsibleUsers() {
        if (!this.selectedCompanyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const responsible = await API.getMainResponsibleUsers(this.selectedCompanyId);

            const dummyResponsible: TPAEmployeePublicUserInfo = {
                id: "",
                username: "",
                firstName: t("common.tpaFirstName"),
                lastName: t("common.tpaLastName"),
                gender: "notSpecified",
                profile_picture_url: undefined,
            };

            if (!responsible) {
                this.mainResponsible = { accounting: dummyResponsible, hr: dummyResponsible };
                return;
            }

            // First responsible for each module is our main responsible
            const accounting =
                responsible.find(pair => pair.role === "tpa-accounting")?.responsible ?? dummyResponsible;
            const hr = responsible.find(pair => pair.role === "tpa-hr")?.responsible ?? dummyResponsible;

            this.mainResponsible = { accounting, hr };
        } catch (error) {
            generalStore.setError(t("error.loadResponsibleUsers"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }

    mainResponsibleSignatureName(module: Module) {
        if (!this.mainResponsible) {
            return t("common.defaultSignature");
        }

        const user = this.mainResponsible[module];
        return user.firstName !== t("common.tpaFirstName") && user.lastName !== t("common.tpaLastName")
            ? getFullName(user)
            : t("common.defaultSignature");
    }

    async loadWaitingUsers() {
        try {
            const response = await API.getCompaniesUsersToRelease();
            this.waitingUsers = response.companies;
        } catch (error) {
            generalStore.setError(t("error.loadWaitingUsers"), error);
        }
    }

    getWaitingUsers(companyId: string) {
        return this.waitingUsers?.find(c => c.id === companyId)?.users.map(u => u.permissions) ?? [];
    }

    private async loadInvitationResults(showDialog = false) {
        const companyStore = this.selectedCompanyStore;
        if (companyStore?.permissions.raw && !companyStore.permissions.isSuperAdmin) {
            try {
                this.invitationResults = await API.getInvitationResults(companyStore.id);

                if (showDialog && this.invitationResults.length > 0) {
                    generalStore.showUserReleaseInfoDialog = true;
                }
            } catch (error) {
                generalStore.setError(t("error.loadInvitationResults"), error);
            }
        }
    }

    @computed get selectedCompanyId() {
        return this._selectedCompanyId;
    }

    getFilteredBankConnections(periodEnd?: moment.MomentInput) {
        const expireDuringPeriod: BankConnection[] = [];
        const upToDate: BankConnection[] = [];
        const expired: BankConnection[] = [];
        const refreshPending: BankConnection[] = [];
        if (periodEnd) {
            for (const connection of this.bankConnections.connections ?? []) {
                // Check if the connection expires before the period end
                if (moment(periodEnd).isSameOrAfter(connection.expiresAt)) {
                    // Check if the connection already expired
                    if (moment().isAfter(connection.expiresAt)) {
                        if (connection.expiresAt?.toString() === BANKING_REFRESHING_CONNECTION_DATE) {
                            refreshPending.push(connection);
                        } else {
                            expired.push(connection);
                        }
                    } else {
                        expireDuringPeriod.push(connection);
                    }
                } else {
                    // If the connection doesnt expire before the period end it is up to date
                    upToDate.push(connection);
                }
            }
        }
        return { expireDuringPeriod, upToDate, expired, refreshPending };
    }

    // Return false if any account wasnt last updated today, else return true
    bankConnectionsWereUpdatedToday = () => {
        for (const connection of this.bankConnections.connections ?? []) {
            for (const account of connection.accounts ?? []) {
                if (!moment(account.lastSuccessfulUpdate).isSame(moment(), "day")) {
                    return false;
                }
            }
        }
        return true;
    };
}

export const companiesStore = new CompaniesStore();

if (import.meta.env.NODE_ENV !== "test") {
    (async () => {
        const hydrate = create({
            storage: (await import("localforage")).default,
        });

        hydrate("companies", companiesStore)
            .then(() => {
                // Override with query param if provided
                const query = queryString.parse(window.location.search);
                if (query.companyId && typeof query.companyId === "string") {
                    companiesStore._selectedCompanyId = query.companyId;
                }
                companiesStore.isRehydrated = true;
            })
            .catch((error: unknown) => {
                debug.error(error);
            });
    })();
}

addStoreToWindow("companiesStore", companiesStore);
