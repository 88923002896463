import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { DocumentDestination, Project } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { CustomSelect } from "../ui/CustomSelect";
import { FormattedMessage } from "../ui/FormattedMessage";
import { TpaWhiteButton } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";

const initValues = (project: Project, archives: DocumentDestination[]) => {
    if (project.archiveId && archives.find(a => a.id === project.archiveId)) {
        return { archiveId: project.archiveId };
    } else if (project.externalId && archives.find(a => a.id === project.externalId)) {
        return { archiveId: project.externalId };
    } else {
        return { archiveId: "" };
    }
};

type IValues = ReturnType<typeof initValues>;

export const FinishProjectDialog = observer(function FinishProjectDialog({
    open,
    onClose,
    onSubmit,
    project,
}: {
    open: boolean;
    onClose: () => void;
    onSubmit: (project: Project, archiveId?: string) => void;
    project: Project;
}) {
    const [archives, setArchives] = React.useState<DocumentDestination[]>([]);

    const companyId = companiesStore.selectedCompanyId;
    const archiveEnabled = companiesStore.selectedCompany?.archiveEnabled ?? false;

    React.useEffect(() => {
        const loadArchives = async () => {
            if (!companyId || !archiveEnabled) {
                return;
            }

            try {
                const response = await API.getArchives(companyId);
                setArchives(response.documentDestinations);
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            }
        };

        loadArchives();
    }, [archiveEnabled, companyId]);

    const handleSubmit = (values: IValues) => {
        if (values.archiveId) {
            onSubmit(project, values.archiveId);
        }
    };

    return (
        <CustomDialog open={open} onClose={onClose}>
            <CustomDialogContent>
                <h1>{t("finishProjectDialog.title")}</h1>
                <p style={{ marginTop: 24 }}>
                    <FormattedMessage id="finishProjectDialog.text" values={{ projectName: project.name }} />
                </p>
                {archiveEnabled && archives.length > 0 && (
                    <Formik
                        initialValues={initValues(project, archives)}
                        onSubmit={handleSubmit}
                        validationSchema={Yup.object().shape({
                            archiveId: Yup.string().required(t("error.requiredField")),
                        })}
                    >
                        <Form id="finishProjectDialogForm">
                            <Field
                                component={CustomSelect}
                                name="archiveId"
                                data-id="finish_project_dialog_archiveId"
                                label={t("common.archive")}
                                options={archives.map(archive => ({
                                    value: archive.id,
                                    label: `${archive.id} - ${archive.description}`,
                                }))}
                                required
                                style={{ marginTop: 24 }}
                            />
                        </Form>
                    </Formik>
                )}
                <ResponsiveButtonContainer style={{ marginTop: 42 }}>
                    <TpaWhiteButton onClick={onClose} data-id="button_cancel">
                        {t("common.cancel")}
                    </TpaWhiteButton>
                    {archiveEnabled && (
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            form="finishProjectDialogForm"
                            data-id="button_finish_project"
                        >
                            {t("menu.finish")}
                        </Button>
                    )}
                    {!archiveEnabled && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onSubmit(project);
                            }}
                            data-id="button_finish_project"
                        >
                            {t("menu.finish")}
                        </Button>
                    )}
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
});
