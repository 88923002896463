import { CARD_SEND_DATE } from "../../config";
import { t } from "../../i18n/util";
import { Report } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { formatDate, getMaxDate } from "../../util/date";
import { getPeriodActionsDatesForSubsidiary } from "../../util/periods";
import { isReportReleased } from "../../util/reports";
import { pushRoute } from "../app/router/history";
import { useDeleteReport } from "../hooks/useDeleteReport";
import { SignReport } from "../hooks/useDocumentSign";
import { IReportsConfig } from "../hooks/useReports";
import { ExpansionCard } from "../ui/ExpansionCard";
import { FormattedMessage } from "../ui/FormattedMessage";
import { ReportList } from "../ui/ReportList";
import { ModuleMainResponsible } from "./ModuleMainResponsible";

interface IProps {
    expanded: boolean;
    title: string;
    module: Module;
    onExpand: (isExpanded: boolean) => void;
    billing: IReportsConfig;
    analysis: IReportsConfig;
    globalBilling: IReportsConfig;
    globalAnalysis: IReportsConfig;
    disabled?: boolean;
    "data-id": string;
}

export const ModuleFinishedCard = ({
    title,
    expanded,
    onExpand,
    module,
    billing,
    analysis,
    globalBilling,
    globalAnalysis,
    disabled,
    "data-id": dataId,
}: IProps) => {
    const moduleStore = getModuleStore(module);
    const deleteReport = useDeleteReport(moduleStore.module);

    const period = moduleStore.selectedPeriod;

    if (!period || !analysis.reports?.reports) {
        return null;
    }

    const getLastDate = (reports: Report[] = []) => {
        return reports.map(report => {
            // If released -> release date counts otherwise upload date
            const isReleased = report.needsRelease && isReportReleased(report);
            return getMaxDate([
                isReleased ? getMaxDate(report.releasers?.map(s => s.releasedAt) ?? []) : undefined,
                !isReleased ? report.document.uploadedAt : undefined,
            ]);
        });
    };

    // Calculate when last report was released
    const lastDate = getMaxDate([
        ...getLastDate(analysis.reports.reports),
        ...getLastDate(billing.reports?.reports),
        ...getLastDate(globalAnalysis.reports?.reports),
        ...getLastDate(globalBilling.reports?.reports),
    ]);

    const handleRelease = (reportsConfig: IReportsConfig) => async (report: SignReport) => {
        await moduleStore.releaseReport({
            companyId: reportsConfig.companyId,
            reportId: report.id,
            isGlobal: reportsConfig.global,
            periodId: reportsConfig.periodId,
            subsidiaryId: reportsConfig.subsidiaryId,
        });
        await reportsConfig.loadReports();
    };

    const handleTicket = (reportsConfig: IReportsConfig) => (report: Report) => {
        pushRoute(moduleStore.routes.REPORT_TICKET, {
            params: { reportId: report.id },
            query: {
                global: reportsConfig.global,
                reportType: reportsConfig.reportType,
                ticketId: report.ticket?.id,
            },
        });
    };

    const reportPermissions = moduleStore.getReportPermissions(moduleStore.selectedSubsidiaryId);

    const status = "closed";

    const pads = period.periodActionsDates;
    const comment = getPeriodActionsDatesForSubsidiary(pads, moduleStore.selectedSubsidiaryId, false)?.reportsComment;

    return (
        <ExpansionCard
            data-id={dataId}
            title={title}
            expanded={expanded}
            onExpand={onExpand}
            status={status}
            iconName="finished"
            date={lastDate && status === "closed" ? { labelId: "dueDate.closedAt", date: lastDate } : undefined}
            disabled={disabled}
        >
            <ModuleMainResponsible module={module} />
            <p style={{ marginTop: 16, whiteSpace: "pre-line" }}>
                {comment ? (
                    <>
                        {authStore.greeting},
                        <br />
                        <br />
                        {comment}
                    </>
                ) : (
                    <FormattedMessage
                        id={moduleStore.getTextId("screen.accounting.overview.finished.message")}
                        values={{
                            salutation: authStore.greeting,
                            signature: companiesStore.mainResponsibleSignatureName(module),
                        }}
                    />
                )}
            </p>
            {CARD_SEND_DATE && (
                <p className="caption" style={{ marginTop: 8 }}>
                    {t("dueDate.sentAt", { date: formatDate(lastDate) })}
                </p>
            )}

            {billing.reports?.reports && billing.reports.reports.length > 0 && (
                <ReportList
                    reportsConfig={billing}
                    title={t("hr.finished.billing")}
                    onRelease={reportPermissions.canReleaseLocal ? handleRelease(billing) : undefined}
                    onDelete={reportPermissions.canDeleteLocal ? deleteReport.handleDelete(billing) : undefined}
                    onTicket={reportPermissions.canReadLocalTickets ? handleTicket(billing) : undefined}
                    createTicketAllowed={false} // We don't allow new tickets for finished periods
                />
            )}
            {analysis.reports.reports.length > 0 && (
                <ReportList
                    reportsConfig={analysis}
                    title={module === "accounting" ? t("accounting.finished.reports") : t("hr.finished.analysis")}
                    onRelease={reportPermissions.canReleaseLocal ? handleRelease(analysis) : undefined}
                    onDelete={reportPermissions.canDeleteLocal ? deleteReport.handleDelete(analysis) : undefined}
                    onTicket={reportPermissions.canReadLocalTickets ? handleTicket(analysis) : undefined}
                    createTicketAllowed={false} // We don't allow new tickets for finished periods
                />
            )}
            {globalBilling.reports?.reports && globalBilling.reports.reports.length > 0 && (
                <ReportList
                    reportsConfig={globalBilling}
                    title={t("hr.finished.globalBilling")}
                    onRelease={reportPermissions.canReleaseGlobal ? handleRelease(globalBilling) : undefined}
                    onDelete={reportPermissions.canDeleteGlobal ? deleteReport.handleDelete(globalBilling) : undefined}
                    onTicket={reportPermissions.canReadGlobalTickets ? handleTicket(globalBilling) : undefined}
                    createTicketAllowed={false} // We don't allow new tickets for finished periods
                />
            )}
            {globalAnalysis.reports?.reports && globalAnalysis.reports.reports.length > 0 && (
                <ReportList
                    reportsConfig={globalAnalysis}
                    title={
                        module === "accounting" ? t("module.finished.globalReports") : t("hr.finished.globalAnalysis")
                    }
                    onRelease={reportPermissions.canReleaseGlobal ? handleRelease(globalAnalysis) : undefined}
                    onDelete={reportPermissions.canDeleteGlobal ? deleteReport.handleDelete(globalAnalysis) : undefined}
                    onTicket={reportPermissions.canReadGlobalTickets ? handleTicket(globalAnalysis) : undefined}
                    createTicketAllowed={false} // We don't allow new tickets for finished periods
                />
            )}
            {deleteReport.dialog}
        </ExpansionCard>
    );
};
