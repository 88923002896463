import { IMessageIDS } from "../../i18n/util";
import { FormattedMessage } from "./FormattedMessage";

export const SearchBarLabel = (props: { count: number; label: IMessageIDS }) => {
    return (
        <p className="caption" style={{ marginTop: 8 }}>
            <FormattedMessage
                id={props.label}
                values={{
                    count: props.count,
                }}
            />
        </p>
    );
};
