import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { FormattedNumber } from "react-intl";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    KPIConfiguration,
    KPIConfigurationEbitBarChart,
    KPIConfigurationEbitValue,
    KPIConfigurationInsuranceCarrierBalance,
    KPIConfigurationRevenueBarChart,
    KPIConfigurationRevenueValue,
    KPIConfigurationSvsAccountBalance,
    KPIConfigurationTaxAccountBalance,
    KPIConfigurationUnpaidAccounts,
} from "../../../types/kpis";
import { formatDate } from "../../../util/helpers";
import { useAPI } from "../../hooks/useAPI";
import { LastCompletedTaxAccountPayment } from "../../kpis/LastCompletedTaxAccountPayment";
import { KpisRoutes } from "../../kpis/router/KpisRoutes";
import { DottedLink, FormattedCurrency, InfoButton } from "../../ui/Primitives";
import { ChartCard, EmptyCard, LoadingCard, SmallCard } from "./KPIBaseCards";
import { useKPIContext } from "./KPIContext";
import { formatMetricDateRange } from "./utils";

interface CardProps<T extends KPIConfiguration> {
    config: T;
    onEdit: (config: T) => void;
    onDelete: (config: T) => void;
    onMove: (dragId: string, hoverId: string) => void;
    moveType: string;
}

export const EbitValue = observer(function EbitValue({
    config,
    onEdit,
    onDelete,
    onMove,
    moveType,
}: CardProps<KPIConfigurationEbitValue>) {
    const { isLoading, kpis } = useKpis(config.settings);

    const handleEdit = () => {
        onEdit(config);
    };
    const handleDelete = () => {
        onDelete(config);
    };

    const editableProps = { onEdit: handleEdit, onDelete: handleDelete, id: config.id, onMove, moveType };

    if (isLoading) {
        return <LoadingCard {...editableProps} />;
    }

    const metric = kpis?.metrics.find(m => m.metricId === "ebit");
    if (!metric) {
        return <EmptyCard name={t("screen.cockpit.kpis.ebit.kpi.name")} {...editableProps} />;
    }

    const { currentValue, changePercentage } = metric;

    return (
        <SmallCard
            name={t("screen.cockpit.kpis.ebit.kpi.name")}
            tooltip={t("screen.cockpit.kpis.ebit.kpi.tooltip")}
            dateRange={formatMetricDateRange(metric)}
            value={
                currentValue != null ? (
                    <FormattedCurrency
                        value={currentValue}
                        currency="EUR"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                    />
                ) : (
                    t("common.notAvailable")
                )
            }
            caption={
                changePercentage != null && (
                    <>
                        {changePercentage > 0 && "+"}
                        <FormattedNumber value={changePercentage / 100} style="percent" maximumFractionDigits={1} />
                    </>
                )
            }
            {...editableProps}
        />
    );
});

export const InsuranceCarrierBalance = observer(function InsuranceCarrierBalance({
    config,
    onEdit,
    onDelete,
    onMove,
    moveType,
}: CardProps<KPIConfigurationInsuranceCarrierBalance>) {
    const { kpiStore } = useKPIContext();

    const { companyId } = kpiStore;
    const { carrierNumber, accountNumber } = config.settings;

    const loader = useCallback(() => {
        return API.getInsuranceCarrierBalance({
            companyId,
            carrierNumber,
            accountNumber,
        });
    }, [accountNumber, carrierNumber, companyId]);
    const result = useAPI(loader, {
        setGeneralStoreIsLoading: false,
    });

    const handleEdit = () => {
        onEdit(config);
    };
    const handleDelete = () => {
        onDelete(config);
    };

    const editableProps = { onEdit: handleEdit, onDelete: handleDelete, id: config.id, onMove, moveType };

    if (result.state === "loading" || result.state === "initial") {
        return <LoadingCard {...editableProps} />;
    }

    if (result.state === "error") {
        // an error is already displayed via `useAPI` above
        return <EmptyCard name={t("screen.cockpit.kpis.insuranceCarrier.balance.kpi.name")} {...editableProps} />;
    }

    const value = result.data.balance;

    return (
        <SmallCard
            name={t("screen.cockpit.kpis.insuranceCarrier.balance.kpi.name")}
            dateRange={t("screen.cockpit.kpis.taxAccount.kpi.label", { date: formatDate(new Date()) })}
            value={
                <FormattedCurrency value={value} currency="EUR" minimumFractionDigits={0} maximumFractionDigits={2} />
            }
            caption={
                value >= 0
                    ? t("screen.cockpit.kpis.taxAccount.kpi.credit")
                    : t("screen.cockpit.kpis.taxAccount.kpi.arrears")
            }
            {...editableProps}
        />
    );
});

export const RevenueValue = observer(function RevenueValue({
    config,
    onEdit,
    onDelete,
    onMove,
    moveType,
}: CardProps<KPIConfigurationRevenueValue>) {
    const { isLoading, kpis } = useKpis(config.settings);

    const handleEdit = () => {
        onEdit(config);
    };
    const handleDelete = () => {
        onDelete(config);
    };

    const editableProps = { onEdit: handleEdit, onDelete: handleDelete, id: config.id, onMove, moveType };

    if (isLoading) {
        return <LoadingCard {...editableProps} />;
    }

    const metric = kpis?.metrics.find(m => m.metricId === "revenue");
    if (!metric) {
        return <EmptyCard name={t("screen.cockpit.kpis.revenue.kpi.name")} {...editableProps} />;
    }

    const { currentValue, changePercentage } = metric;

    return (
        <SmallCard
            name={t("screen.cockpit.kpis.revenue.kpi.name")}
            tooltip={t("screen.cockpit.kpis.revenue.kpi.tooltip")}
            dateRange={formatMetricDateRange(metric)}
            value={
                currentValue != null ? (
                    <FormattedCurrency
                        value={currentValue}
                        currency="EUR"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                    />
                ) : (
                    t("common.notAvailable")
                )
            }
            caption={
                changePercentage != null && (
                    <>
                        {changePercentage > 0 && "+"}
                        <FormattedNumber value={changePercentage / 100} style="percent" maximumFractionDigits={1} />
                    </>
                )
            }
            {...editableProps}
        />
    );
});

export const TaxAccountBalance = observer(function TaxAccountBalance({
    config,
    onEdit,
    onDelete,
    onMove,
    moveType,
}: CardProps<KPIConfigurationTaxAccountBalance>) {
    const { editing, kpiStore } = useKPIContext();

    useEffect(() => {
        kpiStore.loadTaxAccount();
    }, [kpiStore]);

    const isLoading = kpiStore.isLoadingTaxAccount;
    const taxAccount = kpiStore.taxAccount;

    const handleEdit = () => {
        onEdit(config);
    };
    const handleDelete = () => {
        onDelete(config);
    };

    const editableProps = { onEdit: handleEdit, onDelete: handleDelete, id: config.id, onMove, moveType };

    if (isLoading) {
        return <LoadingCard {...editableProps} />;
    }

    if (!taxAccount) {
        return <EmptyCard name={t("screen.cockpit.kpis.taxAccount.kpi.name")} {...editableProps} />;
    }

    const value = taxAccount.value;

    let renderedValue = (
        <FormattedCurrency value={value} currency="EUR" minimumFractionDigits={0} maximumFractionDigits={0} />
    );
    if (!editing) {
        renderedValue = <DottedLink to={KpisRoutes.TAX_ACCOUNT.TRANSACTIONS}>{renderedValue}</DottedLink>;
    }

    return (
        <SmallCard
            name={t("screen.cockpit.kpis.taxAccount.kpi.name")}
            dateRange={t("screen.cockpit.kpis.taxAccount.kpi.label", { date: formatDate(new Date()) })}
            value={renderedValue}
            caption={
                <>
                    {value >= 0
                        ? t("screen.cockpit.kpis.taxAccount.kpi.credit")
                        : t("screen.cockpit.kpis.taxAccount.kpi.arrears")}
                    {taxAccount.lastCompletedPayment ? (
                        <LastCompletedTaxAccountPayment lastCompletedPayment={taxAccount.lastCompletedPayment} />
                    ) : null}
                </>
            }
            {...editableProps}
        />
    );
});

export const SvsAccountBalance = observer(function SvsAccountBalance({
    config,
    onEdit,
    onDelete,
    onMove,
    moveType,
}: CardProps<KPIConfigurationSvsAccountBalance>) {
    const { editing, kpiStore } = useKPIContext();

    useEffect(() => {
        kpiStore.loadSvsAccount();
    }, [kpiStore]);

    const isLoading = kpiStore.isLoadingSvsAccount;
    const svsAccount = kpiStore.svsAccount;

    const handleEdit = () => {
        onEdit(config);
    };
    const handleDelete = () => {
        onDelete(config);
    };

    const editableProps = { onEdit: handleEdit, onDelete: handleDelete, id: config.id, onMove, moveType };

    if (isLoading) {
        return <LoadingCard {...editableProps} />;
    }

    if (!svsAccount) {
        return <EmptyCard name={t("screen.cockpit.kpis.svsAccount.kpi.name")} {...editableProps} />;
    }

    const value = svsAccount.liabilityEnd;

    let renderedValue = (
        <FormattedCurrency value={value} currency="EUR" minimumFractionDigits={0} maximumFractionDigits={0} />
    );
    if (!editing) {
        renderedValue = <DottedLink to={KpisRoutes.SVS_ACCOUNT.TRANSACTIONS}>{renderedValue}</DottedLink>;
    }

    const latestTransaction = svsAccount.transactions?.[svsAccount.transactions.length - 1];
    const latestTransactionQuarter = latestTransaction ? `${latestTransaction.year}/Q${latestTransaction.quarter}` : "";

    return (
        <SmallCard
            dateRange={t("screen.cockpit.kpis.svsAccount.kpi.label", { quarter: latestTransactionQuarter })}
            name={t("screen.cockpit.kpis.svsAccount.kpi.name")}
            value={renderedValue}
            caption={
                <>
                    {value >= 0
                        ? t("screen.cockpit.kpis.taxAccount.kpi.credit")
                        : t("screen.cockpit.kpis.taxAccount.kpi.arrears")}
                    <InfoButton
                        title={t("screen.cockpit.kpis.svsAccount.kpi.info")}
                        color="primary"
                        style={{ padding: 0, marginLeft: 4 }}
                    />
                </>
            }
            {...editableProps}
        />
    );
});

export const UnpaidAccounts = observer(function UnpaidAccounts({
    config,
    onEdit,
    onDelete,
    onMove,
    moveType,
}: CardProps<KPIConfigurationUnpaidAccounts>) {
    const { editing, kpiStore } = useKPIContext();

    useEffect(() => {
        kpiStore.loadUnpaidAccounts();
    }, [kpiStore]);

    const isLoading = kpiStore.isLoadingUnpaidAccounts;
    const unpaidAccounts = kpiStore.unpaidAccounts;

    const handleEdit = () => {
        onEdit(config);
    };
    const handleDelete = () => {
        onDelete(config);
    };

    const editableProps = { onEdit: handleEdit, onDelete: handleDelete, id: config.id, onMove, moveType };

    if (isLoading) {
        return <LoadingCard {...editableProps} />;
    }

    if (!unpaidAccounts) {
        return <EmptyCard name={t("screen.cockpit.kpis.unpaidAccounts.kpi.name")} {...editableProps} />;
    }

    const value = unpaidAccounts.unpaidItem;

    let renderedValue = (
        <FormattedCurrency value={value} currency="EUR" minimumFractionDigits={0} maximumFractionDigits={0} />
    );
    if (!editing) {
        renderedValue = <DottedLink to={KpisRoutes.UNPAID_ACCOUNTS.INVOICES}>{renderedValue}</DottedLink>;
    }

    return (
        <SmallCard
            name={t("screen.cockpit.kpis.unpaidAccounts.kpi.name")}
            dateRange={t("screen.cockpit.kpis.unpaidAccounts.kpi.label", { date: formatDate(new Date()) })}
            value={renderedValue}
            caption={t("screen.cockpit.kpis.unpaidAccounts.kpi.caption", {
                invoicesCount: unpaidAccounts.invoices.length.toString(),
            })}
            {...editableProps}
        />
    );
});

export const EbitBarChart = observer(function EbitBarChart({
    config,
    onEdit,
    onDelete,
    onMove,
    moveType,
}: CardProps<KPIConfigurationEbitBarChart>) {
    const { isLoading, kpis } = useKpis(config.settings);

    const handleEdit = () => {
        onEdit(config);
    };
    const handleDelete = () => {
        onDelete(config);
    };

    const editableProps = { onEdit: handleEdit, onDelete: handleDelete, id: config.id, onMove, moveType };

    if (isLoading) {
        return <LoadingCard {...editableProps} />;
    }

    const metric = kpis?.metrics.find(m => m.metricId === "ebit");
    if (!metric) {
        return <EmptyCard name={t("screen.cockpit.kpis.ebit.chart.name")} {...editableProps} />;
    }

    return <ChartCard metric={metric} name={t("screen.cockpit.kpis.ebit.chart.name")} {...editableProps} />;
});

export const RevenueBarChart = observer(function RevenueBarChart({
    config,
    onEdit,
    onDelete,
    onMove,
    moveType,
}: CardProps<KPIConfigurationRevenueBarChart>) {
    const { isLoading, kpis } = useKpis(config.settings);

    const handleEdit = () => {
        onEdit(config);
    };
    const handleDelete = () => {
        onDelete(config);
    };

    const editableProps = { onEdit: handleEdit, onDelete: handleDelete, id: config.id, onMove, moveType };

    if (isLoading) {
        return <LoadingCard {...editableProps} />;
    }

    const metric = kpis?.metrics.find(m => m.metricId === "revenue");
    if (!metric) {
        return <EmptyCard name={t("screen.cockpit.kpis.revenue.chart.name")} {...editableProps} />;
    }

    return <ChartCard metric={metric} name={t("screen.cockpit.kpis.revenue.chart.name")} {...editableProps} />;
});

function useKpis(settings: { months: number }) {
    const { kpiStore } = useKPIContext();
    const { months } = settings;

    useEffect(() => {
        kpiStore.loadKpis({ months });
    }, [kpiStore, months]);

    const kpis = kpiStore.kpis.get(months);
    if (kpis === "loading") {
        return { isLoading: true, kpis: null } as const;
    }

    return { isLoading: false, kpis } as const;
}
