import type { FormatXMLElementFn } from "intl-messageformat";
import { FormattedMessage as ReactIntlFormattedMessage } from "react-intl";
import { Props as FormattedMessageProps } from "react-intl/src/components/message";
import { IDToHTMLTags, IDToPlaceholders, IMessageIDS } from "../../i18n/util";
import { AllKeysOptional, PartialBy } from "../../util/ts";

type PlaceholderValue = React.ReactNode;
type HTMLTagValue = FormatXMLElementFn<React.ReactNode>;

type Props<
    ID extends IMessageIDS,
    Placeholders extends IDToPlaceholders<ID, PlaceholderValue> = IDToPlaceholders<ID, PlaceholderValue>,
    Tags extends IDToHTMLTags<ID> = IDToHTMLTags<ID>,
    // props for `ReactIntlFormattedMessage`, except `id` and `values` - `id` is our own `IMessageIDS`
> = (Omit<FormattedMessageProps, "id" | "values"> & { id: ID }) &
    // detect placeholders: {...}
    ([Placeholders] extends [never]
        ? { values?: Record<string, PlaceholderValue> }
        : ToPlaceholders<OptionalDefaultPlaceholders<Placeholders>>) &
    // detect HTML tags: <...>
    ([Tags] extends [never] ? { tags?: Record<string, HTMLTagValue> } : ToTags<OptionalDefaultTags<Tags>>);

export function FormattedMessage<ID extends IMessageIDS>(props: Props<ID>) {
    return (
        <ReactIntlFormattedMessage
            {...props}
            values={{ ...defaultPlaceholders, ...defaultTags, ...props.values, ...props.tags }}
        />
    );
}

// mark all `defaultPlaceholders` as optional in `Placeholders`
type OptionalDefaultPlaceholders<Placeholders extends Record<string, unknown>> = PartialBy<
    Placeholders,
    keyof typeof defaultPlaceholders
>;

// mark `values` as optional if all keys are optional (i.e. in `defaultPlaceholders`)
type ToPlaceholders<Placeholders extends Record<string, unknown>> =
    AllKeysOptional<Placeholders> extends true ? { values?: Placeholders } : { values: Placeholders };

// default implementations for some basic values
const defaultPlaceholders = {
    br: <br />, // see https://github.com/formatjs/formatjs/issues/1597#issuecomment-596618330 for why this is needed
} satisfies Record<string, PlaceholderValue>;

// mark all `defaultTags` as optional in `Tags`
type OptionalDefaultTags<Tags extends Record<string, unknown>> = PartialBy<Tags, keyof typeof defaultTags>;

// mark `tags` as optional if all keys are optional (i.e. in `defaultTags`)
type ToTags<Tags extends Record<string, unknown>> =
    AllKeysOptional<Tags> extends true ? { tags?: Tags } : { tags: Tags };

// default implementations for some basic tags
const defaultTags = {
    i: (chunks: React.ReactNode) => <i>{chunks}</i>,
    b: (chunks: React.ReactNode) => <b>{chunks}</b>,
} satisfies Record<string, HTMLTagValue>;
