import { Button, FormControlLabel } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import compact from "lodash/compact";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, useLocation } from "react-router";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Permissions, UserCompany, UserPermissions } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { authStore } from "../../stores/AuthStore";
import { configStore } from "../../stores/ConfigStore";
import { generalStore } from "../../stores/GeneralStore";
import { useTableStore } from "../../stores/TableStore";
import {
    getRoleString,
    getRolesString,
    permissionsIsAdvisor,
    permissionsIsSuperAdmin,
} from "../../util/permissionHelpers";
import { getFullName } from "../../util/user";
import { AdvisorRoutes } from "../advisor/router/AdvisorRoutes";
import { pushRoute, withParams } from "../app/router/history";
import { useConfirmationDialog } from "../hooks/useConfirmationDialog";
import { usePhoneNumberMissingDialog } from "../hooks/usePhoneNumberMissingDialog";
import { useSuccessDialog } from "../hooks/useSuccessDialog";
import { SettingsRoutes } from "../settings/router/SettingsRoutes";
import { BulkRejectButton, BulkReleaseButton } from "../ui/BulkActionButton";
import { EmptyState } from "../ui/EmptyState";
import { FormattedMessage } from "../ui/FormattedMessage";
import { IOSSwitch } from "../ui/IOSSwitch";
import { TpaBadge, TpaTable, TpaTableContainer } from "../ui/Primitives";
import { SiteContent } from "../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../ui/TableHeader";
import { TableSearchBarWithAction } from "../ui/TableSearchBar";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { User, UserRow } from "./UserRow";
import { WaitingUserTable, useWaitingUserTable } from "./WaitingUserTable";

function canSelect(user: User) {
    return !user.roles?.includes("super-admin") && !user.needsRelease;
}

export const UserTable = observer(function UserTable({
    companyId,
    companyName,
    permissions,
    canUpdateUserSettings,
}: {
    companyId: string;
    companyName: string;
    permissions: Permissions;
    canUpdateUserSettings?: boolean;
}) {
    const location = useLocation();
    const isConfigRoute = location.pathname.startsWith(AdvisorRoutes.CONFIG.ROOT);
    const isMobile = React.useContext(MobileContext);
    const [userToInvite, setUserToInvite] = React.useState<UserPermissions>();

    const handleConfirmPhoneNumberMissingDialog = () => {
        if (userToInvite) {
            handleInviteUser(userToInvite);
        } else {
            handleAssignRole();
        }
    };

    const handleClosePhoneNumberMissingDialog = () => {
        setUserToInvite(undefined);
    };

    const phoneNumberMissingDialog = usePhoneNumberMissingDialog({
        onConfirm: handleConfirmPhoneNumberMissingDialog,
        onCancel: handleClosePhoneNumberMissingDialog,
    });

    const invitationSuccessDialog = useSuccessDialog({
        title: t("userManagement.successDialog.newUser.superAdmin.title"),
    });
    const sendChangeUsernameChangeEmailSuccessDialog = useSuccessDialog({
        title: t("userManagement.successDialog.sendUsernameChangeEmail.title"),
    });

    const [initialized, setInitialized] = React.useState(false);

    const isSuperAdmin = permissionsIsSuperAdmin(permissions);
    const isAdvisor = permissionsIsAdvisor(permissions);

    const tableStore = useTableStore<User>("Users", {
        orderBy: "lastName",
        orderDir: "asc",
    });
    tableStore.canSelect = canSelect;

    const { tableParams } = tableStore;
    const loadUsers = React.useCallback(
        async (noCache?: boolean) => {
            if (!companyId) {
                tableStore.items = [];
                tableStore.resetOffset();
                tableStore.totalCount = 0;
                return;
            }

            try {
                generalStore.isLoading = true;

                const res = await API.getUsers(
                    companyId,
                    {
                        ...tableParams,
                        permissions: isSuperAdmin ? "noChanges" : "all",
                    },
                    noCache,
                );
                tableStore.totalCount = res.total;
                tableStore.items = (res.users ?? []).map(user => ({ ...user, ...user.permissions }));
            } catch (err) {
                generalStore.setError(t("error.loadUsers"), err);
            } finally {
                generalStore.isLoading = false;
                setInitialized(true);
            }
        },
        [companyId, isSuperAdmin, tableParams, tableStore],
    );

    const waitingConfig = useWaitingUserTable(companyId, companyName, loadUsers);

    // only one of the tables can have a selection, clear the other one if something gets selected
    waitingConfig.tableStore.onChangeSelection = selection => {
        if (selection.length > 0) {
            tableStore.selectedItems.clear();
        }
    };
    tableStore.onChangeSelection = selection => {
        if (selection.length > 0) {
            waitingConfig.tableStore.selectedItems.clear();
        }
    };

    React.useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const deleteUser = useDeleteUser(companyId, loadUsers);

    if (isConfigRoute && !companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    const handleClickNewUser = () => {
        if (isConfigRoute && companyId) {
            pushRoute(withParams(AdvisorRoutes.CONFIG.USERS_EMPLOYEES, { companyId }));
        } else {
            pushRoute(SettingsRoutes.USER_MANAGEMENT.USERS_EMPLOYEES);
        }
    };

    const pushRoleSite = () => {
        const target = isConfigRoute
            ? withParams(AdvisorRoutes.CONFIG.USER_ROLES, { companyId })
            : SettingsRoutes.USER_MANAGEMENT.USER_ROLES;
        pushRoute(target);
    };

    const handleClickAssignRole = () => {
        const selectedUsers = tableStore.getAllSelectedItems();

        if (selectedUsers.some(user => !user.phoneNumber)) {
            phoneNumberMissingDialog.open();
        } else {
            handleAssignRole();
        }
    };

    const handleAssignRole = () => {
        if (companyId) {
            configStore.selectedEmployees = tableStore.getAllSelectedItems().map(user => ({ ...user, type: "user" }));
            pushRoleSite();
        }
    };

    const handleClickEditUser = (user: UserPermissions) => {
        if (companyId) {
            configStore.selectedEmployees = [{ ...user, type: "user" }];
            pushRoleSite();
        }
    };

    const handleClickInviteUser = (user: UserPermissions) => {
        setUserToInvite(user);
        if (!user.phoneNumber) {
            phoneNumberMissingDialog.open();
        } else {
            handleInviteUser(user);
        }
    };

    const handleInviteUser = async (user: UserPermissions) => {
        if (!user) {
            return;
        }
        invitationSuccessDialog.setMessage(
            <FormattedMessage
                id="userManagement.successDialog.newUser.superAdmin.single.message"
                values={{ userName: getFullName(user) }}
            />,
        );

        try {
            await API.inviteUser(companyId, user.id);
            await loadUsers();
            invitationSuccessDialog.openDialog();
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        } finally {
            setUserToInvite(undefined);
        }
    };

    const handleClickSendUsernameChangeEmail = async (user: UserPermissions) => {
        sendChangeUsernameChangeEmailSuccessDialog.setMessage(
            <FormattedMessage
                id="userManagement.successDialog.changeUsername.message"
                values={{ userName: getFullName(user) }}
            />,
        );

        try {
            generalStore.isLoading = true;
            await API.putChangeUsernameEmail(companyId, user.id);
            await loadUsers();
            sendChangeUsernameChangeEmailSuccessDialog.openDialog();
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.response.type === "NEW_USERNAME_DUPLICATED" &&
                apiError.statusCode === HttpStatusCode.Conflict_409
            ) {
                generalStore.setError(t("error.duplicateUsername"), error);
            } else {
                generalStore.setError(t("error.changeUsernameEmail"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    const headerFields: ITableHeaderConfig[] = compact(
        isMobile
            ? [
                  { column: "lastName", label: "table.label.username" },
                  { column: "role", label: "table.label.role", sort: false },
                  //   { column: "moduleCount", label: "table.label.modules", sort: false },
                  { column: "permissions", label: "table.label.modulePermissions", sort: false },
                  authStore.isAdvisor
                      ? { column: "changeUsername", label: "table.label.changeUsername", sort: false }
                      : undefined,
                  { column: "edit" },
                  isAdvisor ? { column: "deleteUser", label: "table.label.deleteUser", sort: false } : undefined,
                  { column: "expand" },
              ]
            : [
                  { column: "lastName", label: "table.label.username" },
                  { column: "role", label: "table.label.role", sort: false },
                  //   { column: "moduleCount", label: "table.label.modules", sort: false },
                  { column: "permissions", label: "table.label.modulePermissions", sort: false },
                  authStore.isAdvisor
                      ? { column: "changeUsername", label: "table.label.changeUsername", sort: false }
                      : undefined,
                  { column: "edit" },
                  isAdvisor ? { column: "deleteUser", label: "table.label.deleteUser", sort: false } : undefined,
                  { column: "expand" },
              ],
    );

    const canUpdate = !!canUpdateUserSettings || authStore.canUpdateUserSettings;

    // No users and NOT caused by a search term
    const isEmpty = tableStore.getIsEmptyState(generalStore.isLoading);

    // Use `allUsers` to get the total number of waiting users. `tableStore.items` is filtered by the search,
    // so it would be 0 if the search does not match any user, hence the waiting users section would be hidden...
    const openPermissionChangeRequests = waitingConfig.allUsers.length;
    const isEmptyWaitingUsers = openPermissionChangeRequests === 0;

    const activeUsersHasSelection = tableStore.selectedItems.size > 0;
    let tableActionButtonLabel: string | undefined;
    if (canUpdate) {
        tableActionButtonLabel = t(activeUsersHasSelection ? "config.users.assignRole" : "config.users.new");
    }

    if (!initialized) {
        return null;
    }

    const waitingSelected = waitingConfig.tableStore.selectedItems.size > 0;

    return (
        <>
            {isEmpty && (
                <EmptyState
                    title={t("config.users.emptyState.title")}
                    message={<FormattedMessage id="config.users.emptyState.message" />}
                    buttonLabel={t("common.refresh")}
                    onAddEntry={() => loadUsers(true)}
                />
            )}
            {!isEmpty && (
                <>
                    <SiteContent>
                        <div style={{ textAlign: "right", marginBottom: 8 }}>
                            <Button color="primary" onClick={() => loadUsers(true)} endIcon={<RefreshIcon />}>
                                {t("common.refresh")}
                            </Button>
                        </div>
                        <TableSearchBarWithAction
                            label="search.caption.numDespositedPersons"
                            labelSelected="search.caption.numSelected"
                            placeholder="search.placeholder.searchForActiveUsers"
                            select={waitingSelected ? waitingConfig.tableStore : tableStore}
                            search={tableStore.search}
                            totalCount={tableStore.totalCount + waitingConfig.tableStore.items.length}
                            onChangeSearch={search => {
                                tableStore.handleSearchChange(search);
                                waitingConfig.tableStore.handleSearchChange(search);
                            }}
                            buttonLabel={tableActionButtonLabel}
                            onAction={activeUsersHasSelection ? handleClickAssignRole : handleClickNewUser}
                            bulkAction={
                                waitingSelected ? (
                                    <>
                                        <BulkReleaseButton
                                            onClick={() => {
                                                waitingConfig.releaseUsers(
                                                    waitingConfig.tableStore.getAllSelectedItems(),
                                                );
                                            }}
                                        />
                                        <BulkRejectButton
                                            onClick={() => {
                                                waitingConfig.rejectUsers(
                                                    waitingConfig.tableStore.getAllSelectedItems(),
                                                );
                                            }}
                                        />
                                    </>
                                ) : undefined
                            }
                        />
                        {!isConfigRoute && isSuperAdmin && !isEmptyWaitingUsers && (
                            <>
                                <div style={{ display: "flex", alignItems: "center", marginTop: 24 }}>
                                    <h4>{t("config.users.waiting.title")}</h4>
                                    {!!openPermissionChangeRequests && (
                                        <TpaBadge style={{ marginLeft: 8 }}>{openPermissionChangeRequests}</TpaBadge>
                                    )}
                                </div>
                                <WaitingUserTable config={waitingConfig} onEdit={handleClickEditUser} />
                                <h4 style={{ marginTop: 48 }}>{t("config.users.active.title")}</h4>
                            </>
                        )}
                        <TpaTableContainer>
                            <TpaTable
                                style={{
                                    borderCollapse: "collapse", // need collapse for tr borderBottom to work
                                }}
                            >
                                <TableHeader
                                    allowMultiSelectAlways
                                    headerFields={headerFields}
                                    tableStore={tableStore}
                                    select={canUpdate ? tableStore : undefined}
                                />
                                {tableStore.items.map((user, index) => (
                                    <UserRow
                                        key={user.permissions.id}
                                        index={index}
                                        user={user}
                                        select={tableStore}
                                        headerFields={headerFields}
                                        onEdit={handleClickEditUser}
                                        onInvite={handleClickInviteUser}
                                        onDelete={deleteUser.handleClickDeleteUser}
                                        onSendUsernameChangeEmail={handleClickSendUsernameChangeEmail}
                                        isSuperAdmin={isSuperAdmin}
                                        isAdvisor={isAdvisor}
                                        canUpdate={canUpdate}
                                        usernameChanged={user.usernameChanged}
                                        newUsername={user.newUsername}
                                    />
                                ))}
                            </TpaTable>
                        </TpaTableContainer>
                        <tableStore.Pagination />
                    </SiteContent>
                    {tableStore.getIsNoResultState(generalStore.isLoading) && (
                        <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                    )}
                </>
            )}
            {waitingConfig.rejectSuccessDialog.dialog}
            {waitingConfig.releaseSuccessDialog.dialog}
            {invitationSuccessDialog.dialog}
            {sendChangeUsernameChangeEmailSuccessDialog.dialog}
            {deleteUser.components}
            {phoneNumberMissingDialog.dialog}
        </>
    );
});

function useDeleteUser(companyId: string, loadUsers: () => Promise<void>) {
    const [userToDelete, setUserToDelete] = React.useState<UserPermissions>();
    const [companies, setCompanies] = React.useState<UserCompany[]>();
    const [deleteInAllCompanies, setDeleteInAllCompanies] = React.useState<boolean>(false);

    const deleteSuccessDialog = useSuccessDialog({
        title: t("userManagement.successDialog.deleteUser.title"),
    });

    const handleCancel = () => {
        setUserToDelete(undefined);
        setCompanies(undefined);
        setDeleteInAllCompanies(false);
    };

    const handleConfirmDeleteUser = async () => {
        if (!userToDelete) {
            return;
        }

        try {
            generalStore.isLoading = true;

            const userCompanies =
                deleteInAllCompanies && companies ? companies : [{ id: companyId, roles: userToDelete.roles }];
            for (const company of userCompanies) {
                if (company.roles?.includes("super-admin")) {
                    continue;
                }

                await API.deleteUserFromCompany(company.id, userToDelete.id);
            }

            await loadUsers();

            deleteSuccessDialog.setMessage(
                <FormattedMessage
                    id="userManagement.successDialog.deleteUser.single.message"
                    values={{ userName: getFullName(userToDelete) }}
                />,
            );
            deleteSuccessDialog.openDialog();
        } catch (error) {
            generalStore.setError(t("error.deleteUser"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const deleteUserConfirmationDialog = useConfirmationDialog({
        title: t("deleteUser.title"),
        message: (
            <>
                <FormattedMessage id="deleteUser.message" values={{ name: getFullName(userToDelete) }} />
                {companies && companies.length > 1 ? (
                    <div style={{ marginTop: 24, marginBottom: 24 }}>
                        <p style={{ marginBottom: 12 }}>{t("deleteUser.multipleCompanies.message")}</p>
                        <ul style={{ marginBottom: 4, paddingLeft: 16 }}>
                            {companies.map(company => {
                                const roles = company.roles
                                    ? getRolesString({ roles: company.roles, gender: userToDelete?.gender })
                                    : getRoleString({ role: "none", gender: userToDelete?.gender });
                                return (
                                    <li key={company.id}>
                                        {company.name} ({roles})
                                    </li>
                                );
                            })}
                        </ul>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    checked={deleteInAllCompanies}
                                    onChange={(_, checked) => {
                                        setDeleteInAllCompanies(checked);
                                    }}
                                />
                            }
                            label={t("deleteUser.multipleCompanies.confirmLabel")}
                        />
                        <p style={{ color: customColors.error }}>{t("deleteUser.warning.superadminskipped")}</p>
                    </div>
                ) : null}
            </>
        ),
        onCancel: handleCancel,
        onConfirm: handleConfirmDeleteUser,
    });

    const handleClickDeleteUser = async (user: UserPermissions) => {
        const response = await API.getUserCompanies(user.id);
        setCompanies(response.companies);
        setUserToDelete(user);

        deleteUserConfirmationDialog.open();
    };

    const components = (
        <>
            {deleteUserConfirmationDialog.dialog}
            {deleteSuccessDialog.dialog}
        </>
    );

    return { handleClickDeleteUser, components };
}
