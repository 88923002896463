import { IconButton, Link, TableBody } from "@material-ui/core";
import compact from "lodash/compact";
import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Draft, DraftStatusEnum, ProjectItemSignatures, ProjectUser } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { formatDate, formatDateTime } from "../../../util/date";
import { hasFileExtension } from "../../../util/files";
import { escapeHtmlAndConvertLineBreaks, replaceAll, sanitizeHtml } from "../../../util/helpers";
import { getDetailedDraftStatusText, getDraftStatus } from "../../../util/projectHelpers";
import { getFullName } from "../../../util/user";
import { pushRoute, withParams, withParamsAndQuery } from "../../app/router/history";
import { useDocumentSign } from "../../hooks/useDocumentSign";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { CenteredContent } from "../../ui/CenteredContent";
import { ConfirmationDialog } from "../../ui/ConfirmationDialog";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { EmptyState } from "../../ui/EmptyState";
import { FormattedMessage } from "../../ui/FormattedMessage";
import {
    InfoButton,
    OptionalTooltip,
    TableLabel,
    TableRowButton,
    TicketButton,
    TpaTable,
    TpaTableCell,
    TpaTableContainer,
    TpaTableRow,
} from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBar } from "../../ui/TableSearchBar";
import { FileIcon } from "../../util/FileIcon";
import { IIconNames, Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { customColors } from "../../util/Theme";
import { RequestReleaseProjectItemDialog } from "../RequestReleaseProjectItemDialog";
import { ProjectsRoutes } from "../router/ProjectsRoutes";

export const ProjectsReleasesSite = observer(function ProjectsReleasesSite({
    reloadProject,
    onOpenTicketSite,
}: {
    reloadProject: () => Promise<void>;
    onOpenTicketSite: (path: string[], parentId?: string) => void;
}) {
    const { projectId, projectItemId } = useParams<{ projectId?: string; projectItemId?: string }>();
    const [showRequestReleaseConfirmationDialog, setShowRequestReleaseConfirmationDialog] = React.useState(false);
    const [showRequestReleaseDialog, setShowRequestReleaseDialog] = React.useState(false);
    const [showReleaseConfirmationDialog, setShowReleaseConfirmationDialog] = React.useState(false);
    const [draftToEdit, setDraftToEdit] = React.useState<Draft>();
    const [draftToCancel, setDraftToCancel] = React.useState<Draft>();
    const isMobile = React.useContext(MobileContext);
    const [drafts, setDrafts] = React.useState<Draft[]>([]);
    const contextMenu = useContextMenu<Draft>();

    const releaseSuccessDialog = useSuccessDialog({
        title: t("projects.releaseSuccessDialog.title"),
        onClose: async () => {
            // Has to be done here, otherwise screen with successdialog flickers
            await loadDrafts();
        },
    });

    const tableStore = useTableStore("ProjectsReleasesSite", {
        orderBy: "updatedAt",
        orderDir: "desc",
    });

    const companyId = companiesStore.selectedCompanyId;

    const { tableParams } = tableStore;
    const loadDrafts = React.useCallback(
        async (itemId?: string) => {
            if (companyId && projectId) {
                try {
                    generalStore.isLoading = true;

                    const response = await API.getProjectItemDrafts(
                        companyId,
                        projectId,
                        projectItemId ?? itemId,
                        tableParams,
                    );
                    tableStore.totalCount = response.total;

                    if (response.drafts) {
                        setDrafts(response.drafts);
                    }
                } catch (err) {
                    generalStore.setError(t("error.loadCompany"), err);
                } finally {
                    generalStore.isLoading = false;
                }
            }
        },
        [companyId, projectId, projectItemId, tableParams, tableStore],
    );

    React.useEffect(() => {
        loadDrafts();
    }, [loadDrafts]);

    const handleDraftAction = async (draft: Draft, action: "reject" | "release" | "cancel") => {
        if (!companyId || !projectId) {
            return;
        }

        try {
            generalStore.isLoading = true;

            await API.putProjectItemDraftAction(companyId, projectId, draft.projectItemID, draft.id, action);
            await reloadProject();
            await loadDrafts();
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const sign = useDocumentSign({
        companyId,
        projectId,
        onReleaseDraft: handleDraftAction,
        onReload: loadDrafts,
    });

    if (!companyId) {
        // No company selected -> get out
        return null;
    }

    if (!projectId) {
        // No project selected -> get out
        return null;
    }

    const handleCloseCancelConfirmationDialog = () => {
        setDraftToCancel(undefined);
    };

    const handleSubmitCancelConfirmationDialog = async () => {
        if (draftToCancel) {
            await handleDraftAction(draftToCancel, "cancel");
            setDraftToCancel(undefined);
        }
    };

    const handleCloseReleaseConfirmationDialog = () => {
        setDraftToEdit(undefined);
        setShowReleaseConfirmationDialog(false);
    };

    const handleSubmitReleaseConfirmationDialog = async () => {
        if (draftToEdit) {
            await handleDraftAction(draftToEdit, "release");
            setDraftToEdit(undefined);
        }
        setShowReleaseConfirmationDialog(false);
    };

    const handleCloseRequestReleaseConfirmationDialog = () => {
        setDraftToEdit(undefined);
        setShowRequestReleaseConfirmationDialog(false);
    };

    const handleSubmitRequestReleaseConfirmationDialog = () => {
        setShowRequestReleaseConfirmationDialog(false);
        setShowRequestReleaseDialog(true);
    };

    const handleCloseRequestReleaseProjectItemDialog = () => {
        setDraftToEdit(undefined);
        setShowRequestReleaseDialog(false);
    };

    const handleSubmitRequestReleaseProjectItemDialog = async (
        responsibleUsers: ProjectUser[],
        dueDate: string,
        requiresQes?: boolean,
        collectiveRelease?: boolean,
        comment?: string,
        qesPositioning?: ProjectItemSignatures[],
    ) => {
        const responsibleIDs = responsibleUsers.map(responsibleUser => responsibleUser.id);
        if (draftToEdit) {
            try {
                generalStore.isLoading = true;

                await API.putProjectItemDraft(companyId, projectId, {
                    comment: escapeHtmlAndConvertLineBreaks(comment),
                    draftIDs: [draftToEdit.id],
                    dueDate,
                    projectItems: [draftToEdit.projectItemID],
                    requiresQes,
                    responsibleIDs,
                    collectiveRelease,
                    qesPositioning,
                });

                setShowRequestReleaseDialog(false);
                setDraftToEdit(undefined);
                releaseSuccessDialog.setMessage(
                    responsibleUsers.length > 1 ? (
                        t("projects.releaseSuccessDialog.multi.message")
                    ) : (
                        <FormattedMessage
                            id="projects.releaseSuccessDialog.message"
                            values={{ name: getFullName(responsibleUsers[0]) }}
                        />
                    ),
                );
                releaseSuccessDialog.openDialog();
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            } finally {
                generalStore.isLoading = false;
            }
        }
    };

    const handleClickTicket = (draft: Draft) => () => {
        if (projectItemId) {
            if (draft.ticket) {
                pushRoute(
                    withParamsAndQuery(
                        ProjectsRoutes.RELEASES_PROJECT_ITEM_TICKET,
                        { projectId, projectItemId, projectItemDraftId: draft.id },
                        { ticketId: draft.ticket.id },
                    ),
                );
            } else {
                pushRoute(
                    withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM_TICKET, {
                        projectId,
                        projectItemId,
                        projectItemDraftId: draft.id,
                    }),
                );
            }
        } else {
            onOpenTicketSite([draft.name]);

            if (draft.ticket) {
                pushRoute(
                    withParamsAndQuery(
                        ProjectsRoutes.RELEASES_TICKET,
                        { projectId, projectItemId: draft.projectItemID, projectItemDraftId: draft.id },
                        { ticketId: draft.ticket.id },
                    ),
                );
            } else {
                pushRoute(
                    withParams(ProjectsRoutes.RELEASES_TICKET, {
                        projectId,
                        projectItemId: draft.projectItemID,
                        projectItemDraftId: draft.id,
                    }),
                );
            }
        }
    };

    const headerFields: ITableHeaderConfig[] = compact(
        isMobile
            ? [
                  { column: "mimeType", label: "table.label.type" },
                  !projectItemId && { column: "name", label: "table.label.documentName" },
                  { column: "updatedAt", label: "table.label.updatedAt.variant" },
                  !projectItemId && { column: "folder", label: "table.label.folder" },
                  projectItemId && { column: "requestor", label: "table.label.releaseRequestedBy" },
                  { column: "dueDate", label: "table.label.dueDate" },
                  { column: "status", label: "table.label.status" },
                  {
                      column: "ticket",
                      label: "table.label.ticket",
                      sort: false,
                      style: { width: "0%", textAlign: "center" },
                  },
                  {
                      column: "info",
                      label: "table.label.info",
                      sort: false,
                      style: { width: "0%", textAlign: "center" },
                  },
                  { column: "contextMenu" },
              ]
            : [
                  { column: "mimeType", label: "table.label.type" },
                  !projectItemId && { column: "name", label: "table.label.documentName" },
                  { column: "updatedAt", label: "table.label.updatedAt.variant" },
                  !projectItemId && { column: "folder", label: "table.label.folder" },
                  projectItemId && { column: "requestor", label: "table.label.releaseRequestedBy" },
                  { column: "dueDate", label: "table.label.dueDate" },
                  { column: "status", label: "table.label.status" },
                  {
                      column: "ticket",
                      label: "table.label.ticket",
                      sort: false,
                      style: { width: "0%", textAlign: "center" },
                  },
                  {
                      column: "info",
                      label: "table.label.info",
                      sort: false,
                      style: { width: "0%", textAlign: "center" },
                  },
                  { column: "contextMenu" },
              ],
    );

    const tableBody = (
        <TableBody>
            {drafts.map((draft, index) => {
                const isOpenForMe =
                    draft.status === "open" &&
                    draft.releasers.find(
                        releaser => releaser.responsible.id === authStore.userId && releaser.status === "open",
                    );

                return (
                    <TpaTableRow key={draft.id}>
                        {headerFields.map(({ column }, index) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = draft[column as keyof Draft];
                            let style = {};

                            if (column === "name") {
                                label = draft.name;
                            } else if (column === "mimeType") {
                                label = <FileIcon name={draft.name} />;
                            } else if (column === "updatedAt") {
                                label = formatDateTime(draft.updatedAt);
                            } else if (column === "dueDate") {
                                label = formatDate(draft.dueDate);
                            } else if (column === "folder") {
                                // TODO flexible width for folder or just show path on hover at document name?
                                label = draft.folder;
                            } else if (column === "requestor") {
                                label = getFullName(draft.requestor);
                            } else if (column === "ticket") {
                                return (
                                    <TpaTableCell key={column} style={{ textAlign: "center" }}>
                                        {(!authStore.isTpa || draft.ticket) && (
                                            <TicketButton ticket={draft.ticket} onClick={handleClickTicket(draft)} />
                                        )}
                                    </TpaTableCell>
                                );
                            } else if (column === "info") {
                                style = { textAlign: "center" };
                                if (draft.comment) {
                                    label = (
                                        <InfoButton
                                            title={
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: replaceAll(sanitizeHtml(draft.comment), "<br/>", "\n"),
                                                    }}
                                                />
                                            }
                                            color="primary"
                                            style={{ padding: 0 }}
                                        />
                                    );
                                }
                            } else if (column === "status") {
                                const statusIcons: {
                                    [key in DraftStatusEnum]: { name: IIconNames; color?: string };
                                } = {
                                    open: {
                                        name: "time",
                                        color: customColors.greyTextfields,
                                    },
                                    released: {
                                        name: "checkmark",
                                        color: customColors.secondaryColor,
                                    },
                                    rejected: {
                                        name: "closeSmall",
                                        color: customColors.redUrgent,
                                    },
                                    canceled: {
                                        name: "forbidden",
                                    },
                                };

                                if (!authStore.isTpa && isOpenForMe) {
                                    return (
                                        <TpaTableCell
                                            key={column}
                                            style={{
                                                width: 1 /* width 1 limits to content */,
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <TableRowButton
                                                color="primary"
                                                onClick={() => {
                                                    if (!hasFileExtension(draft.name, "pdf")) {
                                                        if (draft.comment) {
                                                            setDraftToEdit(draft);
                                                            setShowReleaseConfirmationDialog(true);
                                                        } else {
                                                            handleDraftAction(draft, "release");
                                                        }
                                                    } else {
                                                        sign.openQesDialog({
                                                            signContext: "draft",
                                                            itemToRelease: draft,
                                                            mimeType: "application/pdf",
                                                        });
                                                    }
                                                }}
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    textTransform: "uppercase",
                                                    padding: "5px 16px 3px 16px",
                                                }}
                                            >
                                                {t("button.release")}
                                            </TableRowButton>
                                            <Link
                                                component="button"
                                                underline="always"
                                                color="inherit"
                                                onClick={() => handleDraftAction(draft, "reject")}
                                                style={{
                                                    textTransform: "lowercase",
                                                    fontSize: 10,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {t("projects.releases.reject")}
                                            </Link>
                                        </TpaTableCell>
                                    );
                                } else {
                                    return (
                                        <TpaTableCell
                                            key={column}
                                            style={{
                                                width: 1,
                                                whiteSpace: "nowrap",
                                                color:
                                                    draft.status === "canceled" ? customColors.placeholder : undefined,
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Icon
                                                    name={statusIcons[draft.status].name}
                                                    style={{
                                                        color: statusIcons[draft.status].color,
                                                        marginRight: 4,
                                                    }}
                                                />
                                                <OptionalTooltip title={getDetailedDraftStatusText(draft)}>
                                                    <div>{getDraftStatus(draft)}</div>
                                                </OptionalTooltip>
                                            </div>
                                        </TpaTableCell>
                                    );
                                }
                            } else if (column === "contextMenu") {
                                return (
                                    <TpaTableCell key={column} style={{ textAlign: "right", width: 1 }}>
                                        <IconButton
                                            data-id={`context_menu_${index}`}
                                            style={{ padding: 0 }}
                                            onClick={event => {
                                                contextMenu.open(event, draft);
                                            }}
                                        >
                                            <Icon name="more" />
                                        </IconButton>
                                    </TpaTableCell>
                                );
                            }

                            return (
                                <TpaTableCell
                                    key={column}
                                    style={{
                                        color: draft.status === "canceled" ? customColors.placeholder : undefined,
                                        ...style,
                                    }}
                                >
                                    <TableLabel>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const item = contextMenu.contextElement;

        contextMenuItems = [
            {
                title: t("menu.download"),
                icon: "download",
                onClick: async () => {
                    contextMenu.close();
                    try {
                        generalStore.isLoading = true;
                        await API.putDownloadProjectDraft(companyId, projectId, [item.id]);
                    } catch (err) {
                        generalStore.setError(t("error.download"), err);
                    } finally {
                        generalStore.isLoading = false;
                    }
                },
                "data-id": "context_menu_download",
            },
            ...(item.status === "open" && authStore.isTpa
                ? ([
                      {
                          title: t("menu.cancelRelease"),
                          icon: "forbidden",
                          onClick: () => {
                              contextMenu.close();
                              setDraftToCancel(item);
                          },
                          "data-id": "context_menu_cancelRelease",
                      },
                      {
                          title: t("menu.editRelease"),
                          icon: "pen",
                          onClick: () => {
                              contextMenu.close();
                              setShowRequestReleaseConfirmationDialog(true);
                              setDraftToEdit(item);
                          },
                          "data-id": "context_menu_editRelease",
                      },
                  ] satisfies ContextMenuItem[])
                : []),
        ];
    }

    // No drafts and NOT caused by a search term
    const isEmpty = tableStore.getIsEmptyState(generalStore.isLoading);

    return (
        <>
            <CenteredContent>
                {isEmpty && (
                    <EmptyState
                        title={t("projects.releases.emptystate.title")}
                        message={t("projects.releases.emptystate.message")}
                    />
                )}
                {!isEmpty && (
                    <>
                        <SiteContent>
                            <TpaTableContainer>
                                {!projectItemId && (
                                    <TableSearchBar
                                        label="search.caption.numDocuments"
                                        placeholder="search.placeholder.searchForFileName"
                                        search={tableStore.search}
                                        totalCount={tableStore.totalCount}
                                        onChangeSearch={tableStore.handleSearchChange}
                                    />
                                )}
                                <TpaTable>
                                    <TableHeader headerFields={headerFields} tableStore={tableStore} />
                                    {tableBody}
                                </TpaTable>
                            </TpaTableContainer>
                            <tableStore.Pagination />
                            <ContextMenu
                                data-id="context_menu"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                config={contextMenu}
                                items={contextMenuItems}
                            />
                        </SiteContent>
                        {tableStore.getIsNoResultState(generalStore.isLoading) && (
                            <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                        )}
                    </>
                )}
            </CenteredContent>
            {draftToCancel && (
                <ConfirmationDialog
                    title={t("projects.cancelRequestedReleaseConfirmationDialog.title")}
                    message={
                        <FormattedMessage
                            id="projects.cancelRequestedReleaseConfirmationDialog.message"
                            values={{ fileName: draftToCancel.name }}
                        />
                    }
                    confirmLabel={t("projects.cancelRequestedReleaseConfirmationDialog.confirm")}
                    onConfirm={handleSubmitCancelConfirmationDialog}
                    onCancel={handleCloseCancelConfirmationDialog}
                    open
                />
            )}
            {draftToEdit && showRequestReleaseConfirmationDialog && (
                <ConfirmationDialog
                    title={t("projects.requestReleaseConfirmationDialog.title")}
                    message={
                        <FormattedMessage
                            id="projects.requestReleaseConfirmationDialog.message"
                            values={{ fileName: draftToEdit.name }}
                        />
                    }
                    confirmLabel={t("projects.requestReleaseConfirmationDialog.confirm")}
                    onConfirm={handleSubmitRequestReleaseConfirmationDialog}
                    onCancel={handleCloseRequestReleaseConfirmationDialog}
                    open
                />
            )}
            {draftToEdit && showRequestReleaseDialog && (
                <RequestReleaseProjectItemDialog
                    companyId={companyId}
                    projectId={projectId}
                    projectItems={[{ id: draftToEdit.projectItemID, name: draftToEdit.name }]}
                    open
                    onClose={handleCloseRequestReleaseProjectItemDialog}
                    onSubmit={handleSubmitRequestReleaseProjectItemDialog}
                />
            )}
            {draftToEdit && showReleaseConfirmationDialog && (
                <ConfirmationDialog
                    title={t("projects.releaseConfirmationDialog.title")}
                    message={
                        <div>
                            {t("projects.releaseConfirmationDialog.message", {
                                name: getFullName(draftToEdit.requestor),
                            })}
                            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(draftToEdit.comment) }} />
                        </div>
                    }
                    confirmLabel={t("button.release")}
                    onConfirm={handleSubmitReleaseConfirmationDialog}
                    onCancel={handleCloseReleaseConfirmationDialog}
                    onClose={handleCloseReleaseConfirmationDialog}
                    open
                />
            )}
            {sign.qesDialog}
            {sign.qesBlockedDialog}
            {releaseSuccessDialog.dialog}
        </>
    );
});
