import { Paper, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { FileRejection } from "react-dropzone";
import { ACCEPTED_FILE_FORMATS, MAX_UPLOAD_SIZE_MB } from "../../config";
import { IMessageIDS, t } from "../../i18n/util";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { generalStore } from "../../stores/GeneralStore";
import { debug } from "../../util/debug";
import { filterEmptyFiles, filterInvalidFiles } from "../../util/files";
import { fileRejectionToErrorMessage, getSupportedFileFormatsString, isIos } from "../../util/helpers";
import { IUpload } from "./DocumentUpload";
import { DropZoneSmall } from "./DropZoneSmall";
import { DialogErrorLine } from "./FailedUploadDialog";
import { FormattedMessage } from "./FormattedMessage";
import { LinkAction } from "./Primitives";

const TEST_FAILED_UPLOADS = false;

export interface IAcceptedFile<MetaData = unknown> {
    file: File;
    metaData?: MetaData;
}

interface IFailedFile<FileWithMetaData extends IAcceptedFile> {
    acceptedFile: FileWithMetaData;
    statusCode: number;
}

export type UploadFile<T, FileWithMetaData extends IAcceptedFile> = (file: FileWithMetaData) => Promise<T | undefined>;

export type OnUpload<T, FileWithMetaData extends IAcceptedFile> = (
    successfulUploads: IUpload<T>[],
    failedUploads: IFailedFile<FileWithMetaData>[],
) => void;

export function useDocumentUploadSmall<T, FileWithMetaData extends IAcceptedFile>(
    uploadFile: UploadFile<T, FileWithMetaData>,
    delayed?: boolean,
    onUpload?: OnUpload<T, FileWithMetaData>,
    acceptedFileTypes: string = ACCEPTED_FILE_FORMATS,
) {
    const [failedUploads, setFailedUploads] = React.useState<IFailedFile<FileWithMetaData>[]>([]);
    const [currentUploadFile, setCurrentUploadFile] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);
    const [rejectedFiles, setRejectedFiles] = React.useState<FileRejection[]>([]);
    const [acceptedFiles, setAcceptedFiles] = React.useState<FileWithMetaData[]>([]);
    const [successfulUploads, setSuccessfulUploads] = React.useState<IUpload<T>[]>([]);

    // Return true if all files have been uploaded, false otherwise
    const uploadFiles = async (accepted: FileWithMetaData[]) => {
        if (!accepted || accepted.length === 0) {
            return false;
        }

        const failed: IFailedFile<FileWithMetaData>[] = [];
        const success: IUpload<T>[] = [];

        // Reset progress bar
        setCurrentUploadFile(0);

        setIsUploading(true);

        // Iterate over file list and upload each file
        for (let i = 0; i < accepted.length; i++) {
            try {
                if (TEST_FAILED_UPLOADS && i % 2 === 1) {
                    throw new Error("upload failed");
                }

                const file = accepted[i];
                const response = await uploadFile(file);
                if (response) {
                    success.push({ file: file.file, response });
                }
            } catch (error) {
                const apiError = getApiError(error);
                failed.push({ acceptedFile: accepted[i], statusCode: apiError?.statusCode ?? 500 });
            } finally {
                // Update progress bar
                setCurrentUploadFile(i + 1);
            }
        }

        // Append new successful uploads to previous ones
        const newSuccess = successfulUploads.concat(success);
        const newFailed = failedUploads.concat(failed);
        setSuccessfulUploads(newSuccess);
        setFailedUploads(newFailed);
        if (onUpload) {
            onUpload(newSuccess, newFailed);
        }

        // Filter out all that were successful or failed -> end result should be empty
        const newAccepted = accepted.filter(accepted => {
            const isSuccess =
                newSuccess.findIndex(
                    success => success.file.name === accepted.file.name && success.file.size === accepted.file.size,
                ) >= 0;
            if (isSuccess) {
                return false;
            }

            const isFailed =
                newFailed.findIndex(
                    failed =>
                        failed.acceptedFile.file.name === accepted.file.name &&
                        failed.acceptedFile.file.size === accepted.file.size,
                ) >= 0;
            if (isFailed) {
                return false;
            }

            return true;
        });
        setAcceptedFiles(newAccepted);
        setIsUploading(false);

        return newAccepted.length === 0;
    };

    const onDrop = async (acceptedDropFiles: File[], fileRejections: FileRejection[]) => {
        if (isIos()) {
            const filtered = filterInvalidFiles(acceptedDropFiles, fileRejections, acceptedFileTypes);
            acceptedDropFiles = filtered.accepted;
            fileRejections = filtered.rejected;
        }
        const filtered = filterEmptyFiles(acceptedDropFiles, fileRejections);

        // Throw away duplicate files and map to IAcceptedFile
        const accepted = filtered.accepted
            .filter(
                // Throw away duplicates that have already been uploaded
                file => successfulUploads.findIndex(s => s.file.name === file.name && s.file.size === file.size) === -1,
            )
            .filter(
                file =>
                    // Throw away those that are already in failed uploads list
                    failedUploads.findIndex(
                        f => f.acceptedFile.file.name === file.name && f.acceptedFile.file.size === file.size,
                    ) === -1,
            )
            // Throw away those that are already in accepted list
            .filter(file => acceptedFiles.findIndex(a => a.file.name === file.name && a.file.size === file.size) === -1)
            .map<FileWithMetaData>(file => ({ file }) as FileWithMetaData);

        setRejectedFiles(filtered.rejected);
        if (!delayed) {
            setAcceptedFiles(accepted);
            if (accepted && accepted.length > 0) {
                await uploadFiles(accepted);
            }
        } else {
            // Append newly dropped files
            setAcceptedFiles([...acceptedFiles].concat(accepted));
        }
    };

    const onDeleteSuccess = (index: number) => {
        if (index !== -1) {
            const newSuccess = [...successfulUploads];
            newSuccess.splice(index, 1);
            setSuccessfulUploads(newSuccess);
        }
    };

    const onDeleteAccepted = (index: number) => {
        if (index !== -1) {
            const newAccepted = [...acceptedFiles];
            newAccepted.splice(index, 1);
            setAcceptedFiles(newAccepted);
        }
    };

    const onRemoveRejected = (index: number) => {
        if (index !== -1) {
            const newRejected = [...rejectedFiles];
            newRejected.splice(index, 1);
            setRejectedFiles(newRejected);
        }
    };

    const onRemoveFailed = (index: number) => {
        if (index !== -1) {
            setFailedUploads(failedUploads.filter((failedUploads, failedUploadIndex) => failedUploadIndex !== index));
        }
    };

    const onRetry = async (index: number) => {
        debug.log(failedUploads);
        if (index < 0 || index >= failedUploads.length) {
            return;
        }

        const file = failedUploads[index].acceptedFile;
        try {
            const response = await uploadFile(file);
            if (response) {
                setSuccessfulUploads([...successfulUploads, { file: file.file, response }]);
                setFailedUploads(
                    failedUploads.filter((failedUpload, failedUploadIndex) => failedUploadIndex !== index),
                );
            }
        } catch (error) {
            generalStore.setError(t("screen.accounting.records.serverUpload.error"), error);
        }
    };

    return {
        isUploading,
        currentUploadFile,
        acceptedFiles,
        rejectedFiles,
        successfulUploads,
        failedUploads,
        acceptedFileTypes,
        uploadFiles,
        onDrop,
        onDeleteSuccess,
        onDeleteAccepted,
        onRetry,
        onRemoveRejected,
        onRemoveFailed,
    };
}

export type UseDocumentUploadSmallConfig<T, FileWithMetaData extends IAcceptedFile> = ReturnType<
    typeof useDocumentUploadSmall<T, FileWithMetaData>
>;

export const UploadSmallPaper = withStyles({
    root: {
        display: "flex",
        marginTop: 16,
        padding: 16,
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
    },
})(Paper);

export const DocumentUploadSmall = observer(function DocumentUploadSmall<
    T,
    FileWithMetaData extends IAcceptedFile,
>(props: {
    title?: IMessageIDS;
    renderAccepted?: (accepted: FileWithMetaData, onDelete: () => void) => React.ReactNode;
    renderSuccess: (upload: IUpload<T>, onDelete: () => void) => React.ReactNode;
    config: UseDocumentUploadSmallConfig<T, FileWithMetaData>;
    "data-id"?: string;
}) {
    const config = props.config;

    const displayFileList =
        config.successfulUploads.length > 0 ||
        config.rejectedFiles.length > 0 ||
        config.failedUploads.length > 0 ||
        (config.acceptedFiles.length > 0 && props.renderAccepted);

    return (
        <UploadSmallPaper>
            {props.title && (
                <h4 style={{ hyphens: "manual", marginBottom: 16 }}>
                    <FormattedMessage id={props.title} />
                </h4>
            )}

            <DropZoneSmall
                currentUploadFile={config.currentUploadFile}
                totalFileCount={config.acceptedFiles.length}
                fileTypes={config.acceptedFileTypes}
                isUploading={config.isUploading}
                onDrop={config.onDrop}
                data-id={props["data-id"]}
            />

            {displayFileList && (
                <div style={{ marginTop: 18, marginBottom: 2 }}>
                    {props.renderAccepted &&
                        config.acceptedFiles.map((accepted, index) => {
                            // We do the check above, so ! is ok
                            return props.renderAccepted?.(accepted, () => {
                                config.onDeleteAccepted(index);
                            });
                        })}
                    {config.successfulUploads.map((upload, index) => {
                        return props.renderSuccess(upload, () => {
                            config.onDeleteSuccess(index);
                        });
                    })}

                    {config.failedUploads.map((failed, index) => (
                        <div style={{ display: "flex", alignItems: "center" }} key={index}>
                            <DialogErrorLine
                                fileName={failed.acceptedFile.file.name}
                                infoTitle={
                                    failed.statusCode === HttpStatusCode.UnsupportedMediaType_415
                                        ? t("screen.accounting.records.serverUpload.typeError")
                                        : t("screen.accounting.records.serverUpload.error")
                                }
                            />
                            <LinkAction
                                onClick={() => {
                                    if (failed.statusCode === HttpStatusCode.UnsupportedMediaType_415) {
                                        config.onRemoveFailed(index);
                                    } else {
                                        config.onRetry(index);
                                    }
                                }}
                                data-id={`retryupload_${index}`}
                            >
                                {t("common.refresh")}
                            </LinkAction>
                        </div>
                    ))}
                    {config.rejectedFiles.map((failedFile, index) => {
                        return (
                            <div style={{ display: "flex", alignItems: "center" }} key={failedFile.file.name}>
                                <DialogErrorLine
                                    errorMessage={fileRejectionToErrorMessage(failedFile)}
                                    fileName={failedFile.file.name}
                                    infoTitle={getSupportedFileFormatsString()}
                                    infoBody={t("documentUpload.max.size", {
                                        maxUploadSizeMb: MAX_UPLOAD_SIZE_MB,
                                    })}
                                    key={index}
                                />
                                <LinkAction
                                    data-id={`removeerror_${index}`}
                                    onClick={() => {
                                        config.onRemoveRejected(index);
                                    }}
                                >
                                    {t("common.ok")}
                                </LinkAction>
                            </div>
                        );
                    })}
                </div>
            )}
        </UploadSmallPaper>
    );
});
