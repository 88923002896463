import { Button, Checkbox, FormControl, MenuItem, Tooltip } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { GLOBAL_FEATURES } from "../../../features";
import { ILocales, SupportedLocales } from "../../../i18n/ILocales";
import { localeToStringKey, t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { UserInterest } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { getRolesString } from "../../../util/permissionHelpers";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useMfaSettingChangeDialog } from "../../hooks/useMfaSettingChangeDialog";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { AvatarUpload } from "../../ui/AvatarUpload";
import { CenteredContent } from "../../ui/CenteredContent";
import { FormattedMessage } from "../../ui/FormattedMessage";
import { NavBarBack } from "../../ui/NavBarBack";
import { OverviewLine } from "../../ui/OverviewLine";
import { StyledSelect, UserProfileFormControlLabel } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { TableFilterButton } from "../../ui/filter/TableFilter";
import { MobileContext } from "../../util/MobileContext";
import { customColors } from "../../util/Theme";
import { Routes } from "../router/Routes";
import { history, pushRoute } from "../router/history";

export const UserProfileSite = observer(function UserProfileSite() {
    const isMobile = React.useContext(MobileContext);
    const [flowToken, setFlowToken] = React.useState("");
    const [isMultiFactorAuthActivated, setIsMultiFactorAuthActivated] = React.useState(false);
    const mfaEnabled = authStore.userInfo?.mfaEnabled;

    React.useEffect(() => {
        setIsMultiFactorAuthActivated(!!mfaEnabled);
    }, [mfaEnabled]);

    const createExternalTasks = authStore.userInfo?.create_external_tasks;

    const mfaActivatedSuccessDialog = useSuccessDialog({
        title: t("mfa.activation.success.dialog.title"),
        onClose: async () => {
            // Has to be done here, otherwise screen with successdialog flickers
            await authStore.loadUserInfo();
        },
    });

    const mfaResendSuccessDialog = useSuccessDialog({
        title: t("mfa.codeResend.success.dialog.title"),
    });

    const handleDeleteAccount = async () => {
        try {
            await API.deleteUser();
            authStore.logout();
        } catch (error) {
            const apiError = getApiError(error);
            generalStore.setError(t("error.deleteUser"), apiError);
        }
    };

    const deleteDialog = useConfirmationDialog({
        title: t("userProfile.deleteAccount.title"),
        message: t("userProfile.deleteAccount.message"),
        confirmLabel: t("common.delete"),
        onConfirm: handleDeleteAccount,
        danger: true,
    });

    // TPAPORTAL-2625 - need to reload user info again since e-mail address could be changed during session
    React.useEffect(() => {
        const load = async () => {
            await authStore.loadUserInfo();
        };

        load();
    }, []);

    const handleClickUpgradeAccount = () => {
        pushRoute(Routes.MS_UPGRADE);
    };

    const handleCompleteMfaActivation = async (model: { code: string }) => {
        try {
            await API.completeMfaSetting({
                code: model.code,
                flowToken,
                newState: isMultiFactorAuthActivated ? false : true,
            });
            handleCloseMfaActivationDialog();

            mfaActivatedSuccessDialog.setTitle(
                isMultiFactorAuthActivated
                    ? t("mfa.deactivation.success.dialog.title")
                    : t("mfa.activation.success.dialog.title"),
            );

            mfaActivatedSuccessDialog.openDialog();
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.response.type === "MFA_CODE_NOT_MATCHED") {
                generalStore.setError(t("error.mfaSettingChange.codeNotMatched"), error);
            } else {
                generalStore.setError(t("error.settings"), error);
            }
        }
    };

    const handleChangeMultiFactorAuth = async () => {
        try {
            const response = await API.changeMfaSetting(isMultiFactorAuthActivated ? "disable" : "enable");

            if (response.mfaCodeRequired && response.flowToken) {
                // when flowtoken is set then user has requested a new code
                if (flowToken) {
                    mfaResendSuccessDialog.openDialog();
                }
                setFlowToken(response.flowToken);
                mfaActivationDialog.open();
            } else {
                await authStore.loadUserInfo();
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.response.type === "MFA_CODE_SENT_ALREADY") {
                if (flowToken) {
                    generalStore.setError(t("error.mfaSettingChange.codeAlreadySent"));
                } else {
                    generalStore.setError(t("error.mfaSettingChange"));
                }
            } else {
                generalStore.setError(t("error.settings"), error);
            }
        }
    };

    const handleCloseMfaActivationDialog = () => {
        setFlowToken("");
        mfaActivationDialog.close();
    };

    const mfaActivationDialog = useMfaSettingChangeDialog({
        onSubmit: handleCompleteMfaActivation,
        onResendCode: handleChangeMultiFactorAuth,
        onClose: handleCloseMfaActivationDialog,
        isMfaActivated: isMultiFactorAuthActivated,
    });

    if (authStore.isLoading) {
        return null;
    }

    const handleChangeBmdTodosForTickets = async () => {
        try {
            authStore.userInfo = await API.patchUserSettings({ createExternalTasks: !createExternalTasks });
        } catch (error) {
            generalStore.setError(t("error.settings"), error);
        }
    };

    const handleUploadAvatar = async (file: File) => {
        try {
            await API.putAvatarImage(file);
            await authStore.loadUserInfo();
        } catch (error) {
            generalStore.setError(t("error.upload"), error);
        }
    };

    const handleChangeSelect = async (event: React.ChangeEvent<{ value: unknown }>) => {
        try {
            authStore.userInfo = await API.patchUserSettings({ locale: event.target.value as ILocales });
            await authStore.loadUserInfo();
        } catch (error) {
            generalStore.setError(t("error.settings"), error);
        }
    };

    const firstName = authStore.userInfo?.given_name ?? "";
    const lastName = authStore.userInfo?.family_name ?? "";

    const roles = companiesStore.selectedCompanyStore?.permissions.raw?.roles;
    let roleString = t("role.noRole");
    if (authStore.isStaffOnly) {
        // If staff is your only role -> show it
        roleString = t("role.staff");
    } else if (roles && roles.length > 0) {
        // If you have any other role than staff then hide it
        roleString = getRolesString({ roles: roles.filter(r => r !== "staff"), gender: authStore.userInfo?.gender });
    }

    return (
        <div>
            <NavBarBack
                title={t("userProfile.navbar.heading")}
                backLabel={t("common.back")}
                onBack={
                    generalStore.lastLocation
                        ? () => {
                              history.goBack();
                          }
                        : undefined
                }
            />
            <CenteredContent>
                <SiteContent style={isMobile ? undefined : { padding: "40px 48px" }}>
                    <div style={{ maxWidth: 655, padding: 32, backgroundColor: customColors.white }}>
                        <AvatarUpload uploadFile={handleUploadAvatar} />
                        <h3 style={{ marginTop: 24 }}>{t("settings.personalData.title")}</h3>
                        <div style={{ marginTop: 24 }}>
                            <OverviewLine
                                title={t("settings.personalData.username")}
                                label={`${firstName} ${lastName}`}
                            />
                            <OverviewLine title={t("settings.personalData.email")} label={authStore.userInfo?.email} />
                            {!authStore.isTpa && (
                                <OverviewLine title={t("settings.personalData.role")} label={roleString} />
                            )}

                            <OverviewLine
                                title={t("settings.personalData.phoneNumber")}
                                label={authStore.userInfo?.phone_number ?? "-"}
                            />

                            {authStore.isTpa && (
                                <OverviewLine
                                    title={t("settings.personalData.location")}
                                    label={authStore.userInfo?.work_location ?? "-"}
                                />
                            )}
                            <OverviewLine
                                title={t("settings.personalData.language")}
                                component={
                                    <FormControl>
                                        <StyledSelect
                                            value={authStore.locale}
                                            onChange={handleChangeSelect}
                                            MenuProps={{
                                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                                transformOrigin: { vertical: "top", horizontal: "left" },
                                                getContentAnchorEl: null,
                                            }}
                                            disableUnderline
                                        >
                                            {SupportedLocales.map((locale: ILocales) => (
                                                <MenuItem key={locale} value={locale}>
                                                    {t(localeToStringKey(locale))}
                                                </MenuItem>
                                            ))}
                                        </StyledSelect>
                                    </FormControl>
                                }
                            />
                            <OverviewLine
                                title={t("settings.personalData.microsoftAccount")}
                                infoText={<FormattedMessage id="settings.personalData.microsoftUpgrade.info" />}
                                label={authStore.userInfo?.has_microsoft_login ? t("common.yes") : t("common.no")}
                                {...(!authStore.userInfo?.has_microsoft_login
                                    ? {
                                          buttonLabel: t("settings.personalData.microsoftUpgrade.label"),
                                          onButtonClick: handleClickUpgradeAccount,
                                      }
                                    : {})}
                            />
                            {!authStore.userInfo?.has_microsoft_login && (
                                <UserProfileFormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={handleChangeMultiFactorAuth}
                                            checked={isMultiFactorAuthActivated}
                                            disabled={!authStore.userInfo?.phone_number && !isMultiFactorAuthActivated}
                                        />
                                    }
                                    label={t("userProfile.multiFactorAuth")}
                                />
                            )}
                            {GLOBAL_FEATURES.bmdTodosForTickets &&
                                authStore.isTpa &&
                                authStore.userInfo?.homeCountryCode === "AT" && (
                                    <UserProfileFormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onChange={handleChangeBmdTodosForTickets}
                                                checked={createExternalTasks}
                                            />
                                        }
                                        label={t("userProfile.createBmdTodos")}
                                    />
                                )}
                            <UserInterestsToggle />
                            {GLOBAL_FEATURES.deleteUserProfile && (
                                <div style={{ marginTop: 24 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            borderColor: customColors.error,
                                            backgroundColor: customColors.error,
                                            border: "1px solid " + customColors.error,
                                        }}
                                        onClick={e => {
                                            deleteDialog.open();
                                            e.stopPropagation();
                                        }}
                                    >
                                        {t("userProfile.deleteAccount.title")}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </SiteContent>
            </CenteredContent>
            {mfaActivatedSuccessDialog.dialog}
            {mfaResendSuccessDialog.dialog}
            {mfaActivationDialog.component}
            {deleteDialog.dialog}
        </div>
    );
});

const UserInterestsToggle = () => {
    const [userInterests, setUserInterests] = React.useState<UserInterest[]>([]);

    const loader = async () => {
        const response = await API.getUserInterests();
        setUserInterests(response.interests);
    };

    React.useEffect(() => {
        loader();
    }, []);

    const toggleInterest = async (interestId: string) => {
        const response = await API.putUserInterestToggle(interestId);
        setUserInterests(prevInterests => {
            const updatedInterests = prevInterests.map(interest => {
                if (interest.id === response.interest.id) {
                    return response.interest;
                }
                return interest;
            });
            return updatedInterests;
        });
    };

    if (userInterests.length === 0) {
        return null;
    }

    return (
        <>
            <h3 style={{ marginTop: 24, marginBottom: 12 }}>{t("userProfile.userInterests.title")}</h3>
            {userInterests.map(interest => (
                <Tooltip title={interest.description ?? ""} key={interest.id}>
                    <TableFilterButton
                        key={interest.id}
                        onClick={() => toggleInterest(interest.id)}
                        isSelected={interest.enabled}
                        data-id={interest.id}
                        style={{ margin: "4px" }}
                    >
                        {interest.subject}
                    </TableFilterButton>
                </Tooltip>
            ))}
        </>
    );
};
