import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useCallback, useState } from "react";
import { t } from "../../i18n/util";
import { CustomDialog } from "../ui/CustomDialog";
import { FormattedMessage } from "../ui/FormattedMessage";

export const useExternalWindow = () => {
    const [url, setUrl] = useState<string>();

    const open = useCallback((url: string, windowName: string, windowFeatures: string) => {
        setUrl(url);
        window.open(url, windowName, windowFeatures);
    }, []);

    const closeDialog = () => {
        setUrl(undefined);
    };

    const components = (
        <CustomDialog open={!!url} onClose={closeDialog}>
            <DialogTitle>{t("externalLinkDialog.title")}</DialogTitle>
            <DialogContent>
                <FormattedMessage
                    id="externalLinkDialog.content"
                    values={{
                        url: url,
                    }}
                    tags={{
                        a: chunks => (
                            <a href={url} target="_blank" rel="noopener noreferrer">
                                {chunks}
                            </a>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="primary" onClick={closeDialog}>
                    {t("common.done")}
                </Button>
            </DialogActions>
        </CustomDialog>
    );

    return { open, components };
};
