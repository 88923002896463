import { Button } from "@material-ui/core";
import { t } from "../../i18n/util";
import { DetailedEmployee } from "../../network/APITypes";
import { usePersonnelFile } from "../hooks/usePersonnelFile";
import { CustomDialog, CustomDialogContent } from "./CustomDialog";
import { FormattedMessage } from "./FormattedMessage";
import { TpaWhiteButton } from "./Primitives";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

interface Props {
    open: boolean;
    count: number;
    companyId: string;
    onSubmit: (employee: DetailedEmployee) => void;
    onCancel: () => void;
}

export const OpenEmployeeDocumentReleasesDialog = ({ open, count, companyId, onSubmit, onCancel }: Props) => {
    const employee = usePersonnelFile(companyId);

    const handleSubmitClick = () => {
        if (employee) {
            onSubmit(employee);
        }
    };

    open = open && !!employee;

    return (
        <CustomDialog open={open}>
            <CustomDialogContent style={{ paddingBottom: 0 }}>
                <h1>{t("openEmployeeDocumentReleasesDialog.title")}</h1>
            </CustomDialogContent>
            <CustomDialogContent style={{ paddingTop: 24, paddingBottom: 24 }}>
                <FormattedMessage
                    id={
                        count === 1
                            ? "openEmployeeDocumentReleasesDialog.releaseMessage.single"
                            : "openEmployeeDocumentReleasesDialog.releaseMessage.multi"
                    }
                    values={{ count }}
                />
            </CustomDialogContent>
            <CustomDialogContent style={{ paddingTop: 0 }}>
                <ResponsiveButtonContainer>
                    <TpaWhiteButton onClick={onCancel} data-id="button_cancel">
                        {t("common.later")}
                    </TpaWhiteButton>
                    <Button variant="contained" color="primary" onClick={handleSubmitClick} data-id="button_submit">
                        {t("openEmployeeDocumentReleasesDialog.submit")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};
