import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { CARD_SEND_DATE } from "../../config";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetRecordTypesResponse } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { periodToString } from "../../stores/ModuleStore";
import { getModuleStore } from "../../stores/moduleStores";
import { IRecordType, Module } from "../../types/models";
import { debug } from "../../util/debug";
import { formatDate } from "../../util/helpers";
import { pushRoute } from "../app/router/history";
import { ExpansionCard } from "../ui/ExpansionCard";
import { FormattedMessage } from "../ui/FormattedMessage";
import { TableRowButton } from "../ui/Primitives";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { ModuleAddRecordsButton } from "./ModuleAddRecordButton";
import { ModuleMainResponsible } from "./ModuleMainResponsible";

interface IProps {
    module: Module;
    expanded: boolean;
    title: string;
    recordTypes: IRecordType[];
    onExpand: (isExpanded: boolean) => void;
    "data-id": string;
}

export const ModuleTicketsWelcomeCard = observer(function ModuleTicketsWelcomeCard({
    module,
    title,
    recordTypes,
    expanded,
    onExpand,
    "data-id": dataId,
}: IProps) {
    const isMobile = React.useContext(MobileContext);
    const moduleStore = getModuleStore(module);

    const status = moduleStore.calculateSubsidiaryStatus();
    const periodFinished = moduleStore.selectedPeriod?.finished === true;

    if (!moduleStore.selectedPeriod || !status) {
        return null;
    }

    let sentDate = new Date(moduleStore.selectedPeriod.periodStart);
    if (status.status === "closed" && status.releasedAt) {
        sentDate = status.releasedAt;
    }

    const period = periodToString(moduleStore.selectedPeriod);
    const lastPeriod =
        moduleStore.selectedPeriod &&
        moment(moduleStore.selectedPeriod.periodStart).subtract(1, "month").format("MMMM YYYY");

    const gotoRecordTypeTickets = (recordTypeId: string) => {
        pushRoute(moduleStore.routes.RECORDS.TICKETS, {
            params: { recordTypeId },
        });
    };

    const handleRecordTypeSelected = (recordType: GetRecordTypesResponse) => async () => {
        const companyId = companiesStore.selectedCompanyId;
        const periodId = moduleStore.selectedPeriodId;
        const subsidiaryId = moduleStore.selectedSubsidiaryId;
        const recordTypeId = recordType.id;

        if ((recordType.ticketCount ?? 0) === 1 && companyId && periodId && subsidiaryId) {
            // Only one ticket -> directly go to ticket
            try {
                generalStore.isLoading = true;

                const ticketsResponse = await API.getRecordTypeTickets({
                    companyId,
                    module,
                    periodId,
                    subsidiaryId,
                    recordTypeId,
                });

                if (ticketsResponse.tickets) {
                    if (ticketsResponse.tickets.length !== 1) {
                        debug.error("### recordType.ticketCount and actual ticket list count mismatch");
                    } else {
                        const ticket = ticketsResponse.tickets[0];
                        pushRoute(moduleStore.routes.RECORDS.TICKET_DETAILS, {
                            params: { ticketId: ticket.id, recordTypeId },
                        });
                    }
                } else {
                    gotoRecordTypeTickets(recordTypeId);
                }
            } catch (err) {
                generalStore.setError(t("error.loadTickets"), err);
            } finally {
                generalStore.isLoading = false;
            }
        } else {
            gotoRecordTypeTickets(recordTypeId);
        }
    };

    return (
        <ExpansionCard
            data-id={dataId}
            expanded={expanded}
            onExpand={onExpand}
            title={title}
            status={periodFinished ? "closed" : status.status}
            iconName="upload"
            date={status.dueDate}
        >
            <ModuleMainResponsible module={module} />
            <p style={{ marginTop: 16 }}>
                <FormattedMessage
                    id={moduleStore.getTextId("screen.accounting.overview.ticketsWelcome.inProgress")}
                    values={{
                        salutation: authStore.greeting,
                        period,
                        lastPeriod,
                        dueDate: moduleStore.selectedPeriod.dueDate
                            ? t("common.dueUntil", { dueDate: formatDate(moduleStore.selectedPeriod.dueDate) })
                            : "",
                        signature: companiesStore.mainResponsibleSignatureName(module),
                    }}
                />
            </p>
            {CARD_SEND_DATE && (
                <p className="caption" style={{ marginTop: 8 }}>
                    {t("dueDate.sentAt", { date: formatDate(sentDate) })}
                </p>
            )}
            <div style={{ marginTop: 16 }}>
                {recordTypes.map(recordType => (
                    <div
                        key={recordType.id}
                        className="body2"
                        style={{
                            display: "flex",
                            alignItems: isMobile ? "flex-start" : "center",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: "space-between",
                            minHeight: 56,
                            padding: "15px 8px 15px 24px",
                            color: customColors.body1Dark,
                            border: `1px solid ${customColors.greyLight}`,
                        }}
                    >
                        <div style={{ flexBasis: 0, flexGrow: 1 }}>{moduleStore.getRecordTypeName(recordType)}</div>
                        <div style={{ flexBasis: 0, flexGrow: 1, textAlign: "center" }}>
                            {t("common.amount")}: {recordType.ticketCount}
                        </div>
                        <div style={{ flexBasis: 0, flexGrow: 1, width: "100%", textAlign: "right" }}>
                            <TableRowButton
                                data-id="release_report"
                                color="primary"
                                onClick={handleRecordTypeSelected(recordType)}
                            >
                                {t("button.answer")}
                            </TableRowButton>
                        </div>
                    </div>
                ))}
            </div>

            <ModuleAddRecordsButton status="closed" moduleStore={moduleStore} />
        </ExpansionCard>
    );
});
