import { Routes } from "../../app/router/Routes";

const prefix = (route: string) => Routes.KPIS + route;

export const KpisRoutes = {
    TAX_ACCOUNT: {
        TRANSACTIONS: prefix("/tax-account/transactions"),
    },
    SVS_ACCOUNT: {
        TRANSACTIONS: prefix("/svs-account/transactions"),
    },
    UNPAID_ACCOUNTS: {
        INVOICES: prefix("/unpaid-accounts/invoices"),
    },
};
