// Not all locales have texterify translations. Disable for now.
export const SupportedLocales = [
    //"sq",
    // "bg",
    // "cs",
    "de",
    "en",
    // "hr",
    // "hu",
    // "pl",
    // "ro",
    // "ru",
    "sk",
    // "sl",
    // "sr",
] as const;
export type ILocales = (typeof SupportedLocales)[number];
