import { createContext, useContext } from "react";
import { KPIStore } from "../../../stores/KPIStore";

export interface KPIContextValue {
    editing: boolean;
    kpiStore: {
        companyId: string;
        loadConfigurations: KPIStore["loadConfigurations"];
        saveConfigurations: KPIStore["saveConfigurations"];
        kpis: KPIStore["kpis"];
        loadKpis: KPIStore["loadKpis"];
        isLoadingTaxAccount: KPIStore["isLoadingTaxAccount"];
        taxAccount: KPIStore["taxAccount"];
        loadTaxAccount: KPIStore["loadTaxAccount"];
        isLoadingSvsAccount: KPIStore["isLoadingSvsAccount"];
        svsAccount: KPIStore["svsAccount"];
        loadSvsAccount: KPIStore["loadSvsAccount"];
        isLoadingUnpaidAccounts: KPIStore["isLoadingUnpaidAccounts"];
        unpaidAccounts: KPIStore["unpaidAccounts"];
        loadUnpaidAccounts: KPIStore["loadUnpaidAccounts"];
    };
}

const Context = createContext<KPIContextValue>({
    editing: false,
    kpiStore: {
        companyId: "",
        loadConfigurations: () => {
            throw new Error("KPIStore not provided");
        },
        saveConfigurations: () => {
            throw new Error("KPIStore not provided");
        },
        kpis: new Map(),
        loadKpis: () => {
            throw new Error("KPIStore not provided");
        },
        isLoadingTaxAccount: false,
        taxAccount: null,
        loadTaxAccount: () => {
            throw new Error("KPIStore not provided");
        },
        isLoadingSvsAccount: false,
        svsAccount: null,
        loadSvsAccount: () => {
            throw new Error("KPIStore not provided");
        },
        isLoadingUnpaidAccounts: false,
        unpaidAccounts: null,
        loadUnpaidAccounts: () => {
            throw new Error("KPIStore not provided");
        },
    },
});

export const useKPIContext = () => {
    return useContext(Context);
};

export const KPIContext = ({
    children,
    value,
}: {
    children: React.ReactNode;
    value: ReturnType<typeof useKPIContext>;
}) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
};
