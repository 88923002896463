import { Button, CircularProgress, IconButton } from "@material-ui/core";
import cloneDeep from "lodash/cloneDeep";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { KPIStore } from "../../../stores/KPIStore";
import {
    KPIConfiguration,
    KPIConfigurationMetric,
    LargeKPIConfiguration,
    SmallKPIConfiguration,
} from "../../../types/kpis";
import { debug } from "../../../util/debug";
import { isNever } from "../../../util/ts";
import { Centered } from "../../ui/Primitives";
import { SwipeableViews } from "../../ui/SwipeableViews";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { Card } from "./KPIBaseCards";
import {
    EbitBarChart,
    EbitValue,
    InsuranceCarrierBalance,
    RevenueBarChart,
    RevenueValue,
    SvsAccountBalance,
    TaxAccountBalance,
    UnpaidAccounts,
} from "./KPICards";
import { KPIContext, KPIContextValue, useKPIContext } from "./KPIContext";
import { useKPIConfigurationDialog } from "./useKPIConfigurationDialog";
import { Size, isLargeKPI, isSmallKPI, metricsBySize } from "./utils";

interface Props {
    kpiStore: KPIStore;
    startInEditMode?: boolean;
    onChange?: (configurations: KPIConfiguration[]) => void;
    header?: React.ReactNode;
    style?: React.CSSProperties;
}

export const KPIs = observer(function KPIs({ kpiStore, startInEditMode, header, onChange, style }: Props) {
    const isMobile = useContext(MobileContext);

    const [editConfigurations, setEditConfigurations] = useState<KPIConfiguration[]>();
    const editing = !!editConfigurations;

    const updateEditConfigurations = (configurations: KPIConfiguration[]) => {
        setEditConfigurations(configurations);
        onChange?.(configurations);
    };

    useEffect(() => {
        const load = async () => {
            await kpiStore.loadConfigurations();
            if (startInEditMode) {
                setEditConfigurations(kpiStore.configurations?.map(c => cloneDeep(c)) ?? []);
            }
        };
        load();
    }, [kpiStore, startInEditMode]);

    const isLoadingConfigurations = kpiStore.isLoadingConfigurations;

    const currentConfigurations = editConfigurations ?? kpiStore.configurations;

    const smallKPIs = useMemo(() => currentConfigurations?.filter(isSmallKPI) ?? [], [currentConfigurations]);
    const largeKPIs = useMemo(() => currentConfigurations?.filter(isLargeKPI) ?? [], [currentConfigurations]);

    const handleClickEditMode = () => {
        updateEditConfigurations(kpiStore.configurations?.map(c => cloneDeep(c)) ?? []);
    };
    const handleSave = async () => {
        if (!editConfigurations) {
            return;
        }

        try {
            await kpiStore.saveConfigurations(editConfigurations);
        } catch (error) {
            return; // an error happened, stay in edit mode
        }

        setEditConfigurations(undefined);
    };

    const kpiConfigurationDialog = useKPIConfigurationDialog();

    const handleAdd = async (size: Size) => {
        if (!editConfigurations) {
            return;
        }

        const config = await kpiConfigurationDialog.open({ metrics: metricsBySize[size] });

        updateEditConfigurations([...editConfigurations, config]);
    };

    const handleEdit = async (config: KPIConfiguration) => {
        if (!editConfigurations) {
            return;
        }

        const updatedConfig = await kpiConfigurationDialog.open({ metrics: [config.metric], initialConfig: config });

        updateEditConfigurations(editConfigurations.map(c => (c === config ? updatedConfig : c)));
    };

    const handleDelete = (config: KPIConfiguration) => {
        if (!editConfigurations) {
            return;
        }

        updateEditConfigurations(editConfigurations.filter(c => c !== config));
    };

    const handleMove = (dragId: string, hoverId: string) => {
        if (!editConfigurations) {
            return;
        }

        const newEditConfigurations = editConfigurations.slice();

        const dragIndex = newEditConfigurations.findIndex(c => c.id === dragId);
        const hoverIndex = newEditConfigurations.findIndex(c => c.id === hoverId);

        const c = newEditConfigurations[dragIndex];
        newEditConfigurations.splice(dragIndex, 1); // remove the dragged config
        newEditConfigurations.splice(hoverIndex, 0, c); // insert it at the hovered config (moving the hovered and the following configs backwards)

        updateEditConfigurations(newEditConfigurations);
    };

    const canEdit = !isLoadingConfigurations && !isMobile && kpiStore.canEdit;

    const contextValue: KPIContextValue = useMemo(() => ({ editing, kpiStore }), [editing, kpiStore]);

    return (
        <div className={editing ? "editing" : undefined} style={style}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 24, height: 30 }}>
                {header ?? <h2>{t("screen.cockpit.overview.kpis.title")}</h2>}
                <div style={{ flex: 1 }} />
                {editing ? (
                    <Button size="small" onClick={handleSave} style={{ textTransform: "uppercase" }} color="primary">
                        {t("screen.cockpit.kpis.save")}
                    </Button>
                ) : (
                    canEdit && (
                        <IconButton size="small" onClick={handleClickEditMode}>
                            <Icon name="pen" />
                        </IconButton>
                    )
                )}
            </div>
            {isLoadingConfigurations && (
                <Centered>
                    <Card>
                        <CircularProgress color="primary" />
                    </Card>
                </Centered>
            )}
            {!isLoadingConfigurations && (
                <KPIContext value={contextValue}>
                    <SmallKPIs
                        configurations={smallKPIs}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onMove={handleMove}
                    />
                    <LargeKPIs
                        configurations={largeKPIs}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onMove={handleMove}
                    />
                    {kpiStore.configurations?.length === 0 && !editing && (
                        <Centered>
                            <Card>{t("screen.cockpit.kpis.noneConfigured")}</Card>
                        </Centered>
                    )}
                    {kpiConfigurationDialog.dialog}
                </KPIContext>
            )}
        </div>
    );
});

const smallCardWidth = 290;

const SmallKPIsRoot = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-gap: 16px;
    margin-top: 16px;

    > * {
        height: 167px;
    }
`;

const SmallKPIs = ({
    configurations,
    onAdd,
    onEdit,
    onDelete,
    onMove,
}: {
    configurations: SmallKPIConfiguration[];
    onAdd: (size: Size) => void;
    onEdit: (config: SmallKPIConfiguration) => void;
    onDelete: (config: SmallKPIConfiguration) => void;
    onMove: (dragId: string, hoverId: string) => void;
}) => {
    const { editing } = useKPIContext();

    const isMobile = useContext(MobileContext);

    if (!configurations.length && !editing) {
        return null;
    }

    const children: React.ReactNode[] = configurations.map((config, i) => {
        const key = config.id;
        switch (config.metric) {
            case KPIConfigurationMetric.ebitValue:
                return (
                    <EbitValue
                        key={key}
                        config={config}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onMove={onMove}
                        moveType={"small"}
                    />
                );
            case KPIConfigurationMetric.insuranceCarrierBalance:
                return (
                    <InsuranceCarrierBalance
                        key={key}
                        config={config}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onMove={onMove}
                        moveType={"small"}
                    />
                );
            case KPIConfigurationMetric.revenueValue:
                return (
                    <RevenueValue
                        key={key}
                        config={config}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onMove={onMove}
                        moveType={"small"}
                    />
                );
            case KPIConfigurationMetric.taxAccountBalance:
                return (
                    <TaxAccountBalance
                        key={key}
                        config={config}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onMove={onMove}
                        moveType={"small"}
                    />
                );
            case KPIConfigurationMetric.svsAccountBalance:
                return (
                    <SvsAccountBalance
                        key={key}
                        config={config}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onMove={onMove}
                        moveType={"small"}
                    />
                );
            case KPIConfigurationMetric.unpaidAccounts:
                return (
                    <UnpaidAccounts
                        key={key}
                        config={config}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onMove={onMove}
                        moveType={"small"}
                    />
                );
            default:
                isNever(config); // check that all cases are handled
                debug.log("Trying to render unknown small KPI configuration", config);
                return null;
        }
    });

    if (editing) {
        children.push(
            <Card key="add">
                <Centered>
                    <Button
                        style={{ textTransform: "uppercase" }}
                        color="primary"
                        onClick={() => {
                            onAdd("small");
                        }}
                    >
                        {t("screen.cockpit.kpis.add")}
                    </Button>
                </Centered>
            </Card>,
        );
    }

    if (isMobile) {
        return <SwipeableViews elementWidth={smallCardWidth}>{children}</SwipeableViews>;
    }

    return <SmallKPIsRoot>{children}</SmallKPIsRoot>;
};

const LargeKPIsRoot = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-gap: 16px;
    margin-top: 16px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-row-gap: 24px;
    }

    > * {
        height: 247px;
    }
`;

const LargeKPIs = ({
    configurations,
    onAdd,
    onEdit,
    onDelete,
    onMove,
}: {
    configurations: LargeKPIConfiguration[];
    onAdd: (size: Size) => void;
    onEdit: (config: LargeKPIConfiguration) => void;
    onDelete: (config: LargeKPIConfiguration) => void;
    onMove: (dragId: string, hoverId: string) => void;
}) => {
    const { editing } = useKPIContext();

    if (!configurations.length && !editing) {
        return null;
    }

    return (
        <LargeKPIsRoot>
            {configurations.map((config, i) => {
                const key = config.id;
                switch (config.metric) {
                    case KPIConfigurationMetric.ebitBarChart:
                        return (
                            <EbitBarChart
                                key={key}
                                config={config}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                onMove={onMove}
                                moveType={"large"}
                            />
                        );
                    case KPIConfigurationMetric.revenueBarChart:
                        return (
                            <RevenueBarChart
                                key={key}
                                config={config}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                onMove={onMove}
                                moveType={"large"}
                            />
                        );
                    default:
                        isNever(config); // check that all cases are handled
                        debug.log("Trying to render unknown large KPI configuration", config);
                        return null;
                }
            })}
            {editing && (
                <Card>
                    <Centered>
                        <Button
                            style={{ textTransform: "uppercase" }}
                            color="primary"
                            onClick={() => {
                                onAdd("large");
                            }}
                        >
                            {t("screen.cockpit.kpis.add")}
                        </Button>
                    </Centered>
                </Card>
            )}
        </LargeKPIsRoot>
    );
};

export const KPIsCurrentCompany = observer(function KPIsCurrentCompany({ style }: { style?: React.CSSProperties }) {
    const kpiStore = companiesStore.selectedCompanyStore?.kpiStore;
    if (!kpiStore) {
        return null;
    }
    return <KPIs kpiStore={kpiStore} style={style} />;
});
