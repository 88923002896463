import { observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import { t } from "../../../i18n/util";
import { ExternalDocument, GeneralDocumentType } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { Module } from "../../../types/models";
import { debug } from "../../../util/debug";
import { accountingStore } from "../../accounting/AccountingStore";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useExternalDocuments } from "../../hooks/useExternalDocuments";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useTransferSuccessDialog } from "../../hooks/useTransferSuccessDialog";
import { useWebhookConfiguration } from "../../hooks/useWebhookConfiguration";
import { hrStore } from "../../hr/HrStore";
import { HrRoutes } from "../../hr/router/HrRoutes";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { CenteredForm } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { IUploadEmployeeDocument, UploadEmployeeDocumentList } from "../UploadEmployeeDocumentList";
import { UploadGeneralDocumentList } from "../UploadGeneralDocumentList";
import { UploadReportList } from "../UploadReportList";

function isEmploymentDocument(documents: ExternalDocument[]) {
    let isEmployee = true;
    let subsidiaryId = "";
    for (let i = 0; i < documents.length && isEmployee; ++i) {
        const doc = documents[i];
        if (i === 0) {
            subsidiaryId = doc.subsidiaryId ?? "";
            if (!subsidiaryId) {
                isEmployee = false;
            }
        }
        if (!doc.employee || doc.subsidiaryId !== subsidiaryId) {
            isEmployee = false;
        }
    }

    return { isEmployee, subsidiaryId };
}

export const WebhookUploadsSite = observer(function WebhookUploadsSite() {
    const query: { source?: string; additionalSourceData?: string; archive?: string; documentId?: string[] } =
        useQueryParams({ arrayKeys: ["documentId"] });
    if (!query.documentId || query.documentId.length === 0) {
        debug.error("### invalid webhook query");
        return <Redirect to={Routes.COCKPIT} />;
    }

    return (
        <WebhookUploads
            source={query.source ?? "TPA"}
            additionalSourceData={query.additionalSourceData}
            archive={query.archive}
            documentIds={query.documentId}
        />
    );
});

const WebhookUploads = observer(function WebhookUploads({
    source,
    additionalSourceData,
    archive,
    documentIds,
}: {
    source?: string;
    additionalSourceData?: string;
    archive?: string;
    documentIds: string[];
}) {
    const periodId = React.useRef<string>();
    const subsidiaryId = React.useRef<string>();
    const [redirectToActiveEmployees, setRedirectToActiveEmployees] = React.useState(false);

    const successDialog = useTransferSuccessDialog(t("dialog.transferReports.success.message"), async () => {
        // Select company
        await coordinator.selectCompanyById(uploadConfig.companyId);

        // Navigate to chosen module
        if (uploadConfig.module === "accounting") {
            if (periodId.current) {
                await accountingStore.selectPeriodById(periodId.current, subsidiaryId.current);
            }
            pushRoute(Routes.ACCOUNTING);
        } else if (uploadConfig.module === "hr") {
            if (uploadConfig.category === "billing" || uploadConfig.category === "analysis") {
                if (periodId.current) {
                    await hrStore.selectPeriodById(periodId.current, subsidiaryId.current);
                }
                pushRoute(Routes.HR);
            } else {
                pushRoute(redirectToActiveEmployees ? HrRoutes.EMPLOYEES.CURRENT : HrRoutes.EMPLOYEES.FORMER);
            }
        } else if (uploadConfig.module === "generalDocuments") {
            pushRoute(Routes.DOCS);
        } else {
            // Failsafe, should not happen
            pushRoute(Routes.COCKPIT);
        }
    });

    const { documents, setDocuments, errorDialog } = useExternalDocuments({
        source,
        additionalSourceData,
        archive,
        documentIds,
    });

    const { isEmployee, subsidiaryId: employeeSubsidiaryId } = isEmploymentDocument(documents);

    const uploadConfig = useWebhookConfiguration({
        showCompany: true,
        chooseCompany: true,
        // Pre select company for the first document (all docs are from the same
        // company since that's how you multiselect in NTCS)
        companyId: documents?.[0]?.companyId,
        module: isEmployee ? "hr" : undefined,
        showCategory: true,
        chooseCategory: true,
        category: isEmployee ? "contractOfEmployment" : undefined,
    });

    const onSubmit = (params?: { periodId?: string; subsidiaryId?: string }) => {
        periodId.current = params?.periodId;
        subsidiaryId.current = params?.subsidiaryId;
        successDialog.openDialog();
    };

    return (
        <>
            <NavBarBack
                cancelTarget={companiesStore.selectedCompanyId ? Routes.COCKPIT : Routes.COMPANY_OVERVIEW}
                title={t("webhook.uploads.navbar")}
            />
            <CenteredContent>
                <SiteContent>
                    <CenteredForm>
                        {uploadConfig.uploadConfiguration}
                        {uploadConfig.isBaseDataSelected && (
                            <>
                                {uploadConfig.isReport && uploadConfig.module !== "" && (
                                    <UploadReportList
                                        companyId={uploadConfig.companyId}
                                        company={uploadConfig.company}
                                        category={uploadConfig.category}
                                        module={uploadConfig.module as Module}
                                        reports={documents}
                                        setReports={setDocuments}
                                        onSubmit={onSubmit}
                                    />
                                )}
                                {uploadConfig.isGeneralDocument &&
                                    uploadConfig.module !== "" &&
                                    uploadConfig.category !== "" && (
                                        <UploadGeneralDocumentList
                                            companyId={uploadConfig.companyId}
                                            category={uploadConfig.category as GeneralDocumentType}
                                            documents={documents}
                                            setDocuments={setDocuments}
                                            onSubmit={onSubmit}
                                        />
                                    )}
                                {uploadConfig.isEmployeeDocument && (
                                    <UploadEmployeeDocumentList
                                        companyId={uploadConfig.companyId}
                                        subsidiaryId={isEmployee ? employeeSubsidiaryId : undefined}
                                        category={uploadConfig.category}
                                        documents={documents as IUploadEmployeeDocument[]}
                                        setDocuments={setDocuments}
                                        onSubmit={onSubmit}
                                        redirectToActiveEmployees={setRedirectToActiveEmployees}
                                    />
                                )}
                            </>
                        )}
                        {!uploadConfig.isBaseDataSelected && (
                            <UploadReportList reports={documents} setReports={setDocuments} namesOnly />
                        )}
                    </CenteredForm>
                </SiteContent>
            </CenteredContent>
            {successDialog.component}
            {errorDialog}
        </>
    );
});
