export enum KPIConfigurationMetric {
    ebitBarChart = "ebit/bar-chart",
    ebitValue = "ebit/value",
    insuranceCarrierBalance = "insuranceCarrier/balance",
    revenueBarChart = "revenue/bar-chart",
    revenueValue = "revenue/value",
    taxAccountBalance = "taxAccount/balance",
    svsAccountBalance = "svsAccount/balance",
    unpaidAccounts = "unpaidAccounts",
}

export interface KPIConfigurationBase {
    // a unique ID only used on the client to identify the KPI for reordering
    // see https://github.com/react-dnd/react-dnd/issues/236#issuecomment-396068212
    id: string;
}

export interface KPIConfigurationSettingsMonths {
    months: number;
}

export type KPIConfigurationEbitBarChart = KPIConfigurationBase & {
    metric: KPIConfigurationMetric.ebitBarChart;
    settings: KPIConfigurationSettingsMonths;
};
export type KPIConfigurationEbitValue = KPIConfigurationBase & {
    metric: KPIConfigurationMetric.ebitValue;
    settings: KPIConfigurationSettingsMonths;
};

export type KPIConfigurationInsuranceCarrierBalance = KPIConfigurationBase & {
    metric: KPIConfigurationMetric.insuranceCarrierBalance;
    settings: {
        carrierNumber: string;
        accountNumber: string;
    };
};

export type KPIConfigurationRevenueBarChart = KPIConfigurationBase & {
    metric: KPIConfigurationMetric.revenueBarChart;
    settings: KPIConfigurationSettingsMonths;
};
export type KPIConfigurationRevenueValue = KPIConfigurationBase & {
    metric: KPIConfigurationMetric.revenueValue;
    settings: KPIConfigurationSettingsMonths;
};

export type KPIConfigurationTaxAccountBalance = KPIConfigurationBase & {
    metric: KPIConfigurationMetric.taxAccountBalance;
};

export type KPIConfigurationSvsAccountBalance = KPIConfigurationBase & {
    metric: KPIConfigurationMetric.svsAccountBalance;
};

export type KPIConfigurationUnpaidAccounts = KPIConfigurationBase & {
    metric: KPIConfigurationMetric.unpaidAccounts;
};

export type SmallKPIConfiguration =
    | KPIConfigurationEbitValue
    | KPIConfigurationInsuranceCarrierBalance
    | KPIConfigurationRevenueValue
    | KPIConfigurationTaxAccountBalance
    | KPIConfigurationSvsAccountBalance
    | KPIConfigurationUnpaidAccounts;

export type LargeKPIConfiguration = KPIConfigurationEbitBarChart | KPIConfigurationRevenueBarChart;

export type KPIConfigurationWithMonths =
    | KPIConfigurationEbitBarChart
    | KPIConfigurationEbitValue
    | KPIConfigurationRevenueBarChart
    | KPIConfigurationRevenueValue;

export type KPIConfiguration = SmallKPIConfiguration | LargeKPIConfiguration;
