import { Button, Divider } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { IMessageIDS, t } from "../../i18n/util";
import { API } from "../../network/API";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { ModuleStore, periodToString } from "../../stores/ModuleStore";
import { getModuleStore } from "../../stores/moduleStores";
import { IPeriod, IRecord, IRecordType, Module } from "../../types/models";
import { formatDate } from "../../util/helpers";
import { useResize } from "../hooks/useResize";
import { ConfirmationDialog } from "../ui/ConfirmationDialog";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { CustomSelect } from "../ui/CustomSelect";
import { FormattedMessage } from "../ui/FormattedMessage";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";
import { FileIcon } from "../util/FileIcon";

export const TransferRecordsDialog = ({
    moduleStore,
    open,
    onClose,
    onSubmit,
    period,
    recordType,
}: {
    moduleStore: ModuleStore;
    open: boolean;
    onClose: () => void;
    onSubmit: () => Promise<void>;
    period?: IPeriod;
    recordType?: IRecordType;
}) => {
    const [submitting, setSubmitting] = React.useState(false);

    const handleSubmit = async () => {
        if (submitting) {
            return;
        }

        setSubmitting(true);
        await onSubmit();
        setSubmitting(false);
    };

    let textId: IMessageIDS;
    if (moduleStore.module === "accounting") {
        textId = "screen.accounting.records.transfer.dialog.text";
        if (recordType) {
            textId = "screen.accounting.records.transfer.dialog.textRecordType";
        }
    } else {
        textId = "screen.hr.records.transfer.dialog.text";
        if (recordType) {
            textId = "screen.hr.records.transfer.dialog.textRecordType";
        }
    }

    return (
        <CustomDialog onClose={onClose} open={open}>
            <CustomDialogContent>
                <h1>{moduleStore.t("screen.accounting.records.transfer.dialog.title")}</h1>
                <div style={{ marginTop: 24 }}>
                    <FormattedMessage
                        id={textId}
                        values={
                            {
                                name: recordType ? moduleStore.getRecordTypeName(recordType) : "",
                                periodStart: formatDate(period?.periodStart),
                                periodEnd: formatDate(period?.periodEnd),
                            } as never
                        }
                    />
                </div>
                <div style={{ marginTop: 42, textAlign: "right" }}>
                    <ResponsiveButtonContainer>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={onClose}
                            data-id="transfer_records_dialog_cancel_button"
                        >
                            {t("common.cancel")}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={submitting}
                            onClick={handleSubmit}
                            data-id="transfer_records_dialog_submit_button"
                        >
                            {t("screen.accounting.records.transfer.button.text")}
                        </Button>
                    </ResponsiveButtonContainer>
                </div>
            </CustomDialogContent>
        </CustomDialog>
    );
};

export interface IMoveRecordsModel {
    newSubsidiaryId: string;
    newPeriodId: string;
    newRecordTypeId: string;
}

export const MoveRecordsDialog = observer(function MoveRecordsDialog({
    module,
    open,
    onClose,
    onSubmit,
    records,
    defaultRecordTypeId,
}: {
    module: Module;
    open: boolean;
    onClose: () => void;
    onSubmit: (model: IMoveRecordsModel) => void;
    records: IRecord[];
    defaultRecordTypeId?: string;
}) {
    const RECORD_LINE_HEIGHT = 48; // 24px height + 24px margin-bottom
    const moduleStore = getModuleStore(module);
    const mt = moduleStore.t;

    const [recordTypes, setRecordTypes] = React.useState(moduleStore.recordTypes);
    const [subsidiaries, setSubsidiaries] = React.useState(moduleStore.subsidiaries);
    const [hasVerticalScrollbar, setHasVerticalScrollbar] = React.useState(false);
    const listRef = React.useRef<HTMLDivElement | null>(null);
    const dimensions = useResize(listRef);

    React.useEffect(() => {
        if (listRef.current) {
            setHasVerticalScrollbar(listRef.current.scrollHeight > listRef.current.clientHeight);
        }
    }, [dimensions]);

    return (
        <CustomDialog onClose={onClose} open={open}>
            <Formik
                initialValues={{
                    newSubsidiaryId: moduleStore.selectedSubsidiaryId ?? "",
                    newPeriodId: moduleStore.selectedPeriodId ?? "",
                    newRecordTypeId: defaultRecordTypeId ?? "",
                }}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    newSubsidiaryId: Yup.string().required(),
                    newPeriodId: Yup.string().required(),
                    newRecordTypeId: Yup.string().required(),
                })}
            >
                {({ setFieldValue, values, isValid, isSubmitting }) => {
                    const periods = moduleStore.periods;

                    const reloadRecordTypes = async (subsidiaryId: string) => {
                        const periodId = values.newPeriodId;
                        let recordTypeId = values.newRecordTypeId;

                        if (periodId && subsidiaryId && companiesStore.selectedCompanyId) {
                            try {
                                const recordTypesData = await API.getRecordTypes({
                                    companyId: companiesStore.selectedCompanyId,
                                    module,
                                    periodId,
                                    subsidiaryId,
                                });

                                setFieldValue("newRecordTypeId", ""); // Prevents mui out-of-bounds console warning
                                setRecordTypes(recordTypesData);

                                // New recordTypeId after reload
                                if (!recordTypesData.find(recordType => recordType.id === recordTypeId)) {
                                    recordTypeId = recordTypesData[0]?.id ?? "";
                                }

                                setFieldValue("newRecordTypeId", recordTypeId);
                            } catch (err) {
                                generalStore.setError(t("error.loadRecordTypes"), err);
                            }
                        }
                    };

                    const handleChangeSubsidiary = async (event: React.ChangeEvent<{ value: string }>) => {
                        const subsidiaryId = event.target.value;
                        await reloadRecordTypes(subsidiaryId);
                    };

                    const handleChangePeriod = async (event: React.ChangeEvent<{ value: string }>) => {
                        const periodId = event.target.value;
                        let subsidiaryId = values.newSubsidiaryId;
                        try {
                            if (companiesStore.selectedCompanyId) {
                                const response = await API.getSubsidiaries({
                                    companyId: companiesStore.selectedCompanyId,
                                    module,
                                    periodId,
                                });

                                setFieldValue("newSubsidiaryId", ""); // Prevents mui out-of-bounds console warning
                                setSubsidiaries(response);

                                // New subsidiaryId after reload
                                if (!response.find(subsidiary => subsidiary.id === subsidiaryId)) {
                                    subsidiaryId = response[0]?.id ?? "";
                                }

                                setFieldValue("newSubsidiaryId", subsidiaryId);
                            }
                        } catch (err) {
                            generalStore.setError(t("error.loadSubsidiary"), err);
                        }

                        await reloadRecordTypes(subsidiaryId);
                    };

                    return (
                        <>
                            <div style={{ padding: "32px 42px 24px 42px" }}>
                                <h1>
                                    {mt(
                                        records.length === 1
                                            ? "screen.accounting.records.move.single.dialog.title"
                                            : "screen.accounting.records.move.multiple.dialog.title",
                                    )}
                                </h1>
                            </div>
                            {hasVerticalScrollbar && <Divider />}
                            <div
                                ref={listRef}
                                style={{
                                    padding: "10px 42px 10px 42px",
                                    overflow: "auto",
                                    minHeight:
                                        records.length >= 2
                                            ? 2 * RECORD_LINE_HEIGHT
                                            : records.length * RECORD_LINE_HEIGHT,
                                }}
                            >
                                {records.map((record, index) => (
                                    <div
                                        key={record.id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: index < records.length - 1 ? 24 : 0,
                                        }}
                                    >
                                        <FileIcon name={record.document?.name} style={{ marginRight: 16 }} />
                                        <span className="body2">{record.document?.name}</span>
                                    </div>
                                ))}
                            </div>
                            {hasVerticalScrollbar && <Divider />}
                            <div style={{ padding: "24px 42px 0px 42px" }}>
                                <Form id="moveRecordsForm">
                                    <Field
                                        component={CustomSelect}
                                        name="newPeriodId"
                                        data-id="move_record_booking_period"
                                        label={mt("common.accounting.period")}
                                        onChange={handleChangePeriod}
                                        options={periods.map(period => ({
                                            value: period.id,
                                            label: periodToString(period, true),
                                        }))}
                                        required
                                        style={{ marginTop: 10 }}
                                    />
                                    <Field
                                        component={CustomSelect}
                                        name="newSubsidiaryId"
                                        data-id="move_record_subsidiary"
                                        label={mt("common.accounting.subsidiary")}
                                        onChange={handleChangeSubsidiary}
                                        options={subsidiaries.map(subsidiary => ({
                                            value: subsidiary.id,
                                            label: subsidiary.name,
                                        }))}
                                        required
                                    />
                                    <Field
                                        component={CustomSelect}
                                        name="newRecordTypeId"
                                        data-id="move_record_folder"
                                        label={t("common.folder")}
                                        options={recordTypes.map(recordType => ({
                                            value: recordType.id,
                                            label: moduleStore.getRecordTypeName(recordType),
                                        }))}
                                        required
                                        style={{ marginTop: 10 }}
                                    />
                                </Form>
                            </div>
                            <div style={{ padding: "0px 42px 24px 42px", textAlign: "right" }}>
                                <ResponsiveButtonContainer>
                                    <Button
                                        data-id="move_records_dialog_cancel_button"
                                        color="primary"
                                        variant="outlined"
                                        onClick={onClose}
                                    >
                                        {t("common.cancel")}
                                    </Button>
                                    <Button
                                        data-id="move_records_dialog_submit_button"
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        form="moveRecordsForm"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        {t("screen.accounting.records.move.dialog.button.text")}
                                    </Button>
                                </ResponsiveButtonContainer>
                            </div>
                        </>
                    );
                }}
            </Formik>
        </CustomDialog>
    );
});

export interface IRecordToDelete {
    id: string;
    document?: {
        name?: string;
    };
}

export const DeleteRecordsDialog = (props: {
    open: boolean;
    records: IRecordToDelete[];
    onDelete: () => void;
    onCancel: () => void;
}) => {
    const message = (
        <FormattedMessage
            id={props.records.length > 1 ? "deleteDialog.message.plural" : "deleteDialog.message.singular"}
            values={{ documents: props.records.map(record => record.document?.name).join(", ") }}
        />
    );

    return (
        <ConfirmationDialog
            open={props.open}
            title={t("deleteDialog.title")}
            message={message}
            onConfirm={props.onDelete}
            onCancel={props.onCancel}
        />
    );
};

export interface IDocumentToDelete {
    id: string;
    document?: {
        name?: string;
    };
}

export const DeleteEmployeeDocumentDialog = (props: {
    open: boolean;
    documents: IDocumentToDelete[];
    onDelete: () => void;
    onCancel: () => void;
}) => {
    const message = (
        <FormattedMessage
            id={props.documents.length > 1 ? "deleteDialog.message.plural" : "deleteDialog.message.singular"}
            values={{ documents: props.documents.map(document => document.document?.name).join(", ") }}
        />
    );

    return (
        <ConfirmationDialog
            open={props.open}
            title={t("deleteDialog.title")}
            message={message}
            onConfirm={props.onDelete}
            onCancel={props.onCancel}
        />
    );
};
