import { Button, Popover, Tooltip } from "@material-ui/core";
import { Location } from "history";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Account, ExternalDocument } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { ViewerFile, viewerStore } from "../../../stores/ViewerStore";
import { formatDate, toCurrency } from "../../../util/helpers";
import { withParams } from "../../app/router/history";
import { RenderCell } from "../../ui/GridTable";
import { DottedLink, TableLabel } from "../../ui/Primitives";
import { Icon } from "../../util/Icon";
import { Currency, Pill } from "../ResultsValue";
import { ResultsRoutes } from "../router/ResultsRoutes";
import { formatAccountNumber } from "../utils";
import { EnhancedAccountTransaction } from "./useAccountTransactions";

export function useResultsAccountSheetTableRenderCell(
    companyId: string,
    financialAccountancyId: string,
    account: Account,
): RenderCell<EnhancedAccountTransaction> {
    return useCallback(
        (accountTransaction, column) => {
            switch (column.column) {
                case "postingSymbol":
                    return accountTransaction.postingSymbol;

                case "documentNumber":
                    return accountTransaction.documentNumber;

                case "postingText":
                    return <TableLabel style={{ maxWidth: undefined }}>{accountTransaction.postingText}</TableLabel>;

                case "documentDate":
                    return formatDate(accountTransaction.documentDate);

                case "amount": {
                    let content = (
                        <CurrentCurrency
                            value={accountTransaction.amount}
                            currency={toCurrency(accountTransaction.currency)}
                            currencyPosition="start"
                        />
                    );
                    if (
                        accountTransaction.foreignCurrencyAmount &&
                        !accountTransaction.foreignCurrencyAmount.isZero()
                    ) {
                        content = (
                            <FlexColumn>
                                {content}
                                <ForeignCurrency
                                    value={accountTransaction.foreignCurrencyAmount}
                                    currency={accountTransaction.foreignCurrency}
                                    currencyPosition="start"
                                />
                            </FlexColumn>
                        );
                    }
                    return content;
                }

                case "openItemAmount":
                    if (
                        accountTransaction.isOpenItem &&
                        accountTransaction.openItemAmount &&
                        !accountTransaction.openItemAmount.isZero()
                    ) {
                        let content = (
                            <CurrentCurrency
                                value={accountTransaction.openItemAmount}
                                currency={toCurrency(accountTransaction.currency)}
                                currencyPosition="start"
                            />
                        );
                        if (
                            accountTransaction.foreignCurrencyOpenItemAmount &&
                            !accountTransaction.foreignCurrencyOpenItemAmount.isZero()
                        ) {
                            content = (
                                <FlexColumn>
                                    {content}
                                    <ForeignCurrency
                                        value={accountTransaction.foreignCurrencyOpenItemAmount}
                                        currency={accountTransaction.foreignCurrency}
                                        currencyPosition="start"
                                    />
                                </FlexColumn>
                            );
                        }
                        return content;
                    } else {
                        return <Pill variant="green">{t("results.accountSheet.paid")}</Pill>;
                    }

                case "documents": {
                    const { documents } = accountTransaction;
                    if (!documents?.length) {
                        return null;
                    }
                    return (
                        <Documents
                            companyId={companyId}
                            financialAccountancyId={financialAccountancyId}
                            account={account}
                            accountTransaction={accountTransaction}
                            documents={documents}
                        />
                    );
                }

                case "offsettingAccounts":
                    return (
                        <OffsettingAccounts
                            financialAccountancyId={financialAccountancyId}
                            account={account}
                            accountTransaction={accountTransaction}
                            offsettingAccounts={accountTransaction.offsettingAccounts}
                        />
                    );

                default:
                    return null;
            }
        },
        [account, companyId, financialAccountancyId],
    );
}

const CurrentCurrency = styled(Currency)`
    font-weight: bold;
`;
const ForeignCurrency = styled(Currency)`
    font-size: 0.9em;
`;

const DocumentsDownloadAllButton = styled(Button)`
    list-style: none;
    text-align: right;
`;
const DocumentsList = styled.ul`
    margin-top: 16px;
    list-style: none;
`;
const DocumentsListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 16px;
`;
const DocumentLink = styled(DottedLink)`
    align-items: center;
    display: inline-flex;
`;

interface DocumentsProps {
    companyId: string;
    financialAccountancyId: string;
    account: Account;
    accountTransaction: EnhancedAccountTransaction;
    documents: ExternalDocument[];
}

const documentId = (document: ExternalDocument) =>
    `${document.source ?? ""}/${document.additionalSourceData ?? ""}/${document.archiveId ?? ""}/${document.documentId ?? ""}`;

const Documents = ({ companyId, financialAccountancyId, account, accountTransaction, documents }: DocumentsProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setAnchorEl(ev.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleDownloadAllClick = async () => {
        try {
            await API.putDownloadAccountTransactionsDocuments({
                companyId,
                financialAccountancyId,
                accountNr: account.number,
                body: {
                    transactionName: accountTransaction.postingText,
                    documents,
                },
            });
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        }
    };
    const handleDownloadClick = async (ev: React.MouseEvent, document: ExternalDocument) => {
        ev.preventDefault();

        try {
            await API.putDownloadAccountTransactionsDocuments({
                companyId,
                financialAccountancyId,
                accountNr: account.number,
                body: {
                    transactionName: accountTransaction.postingText,
                    documents: [document],
                },
            });
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        }
    };
    const handleOpenClick = (selectedDocument: ExternalDocument) => {
        setAnchorEl(undefined);

        const files = documents.map<ViewerFile>(document => {
            return {
                id: documentId(document),
                name: document.name,
                src: () => {
                    return API.getAccountTransactionsDocumentDownloadUrl({
                        companyId,
                        financialAccountancyId,
                        accountNr: account.number,
                        body: {
                            transactionName: accountTransaction.postingText,
                            documents: [document],
                        },
                    });
                },
                download: () => {
                    return API.putDownloadAccountTransactionsDocuments({
                        companyId,
                        financialAccountancyId,
                        accountNr: account.number,
                        body: {
                            transactionName: accountTransaction.postingText,
                            documents: [document],
                        },
                    });
                },
            };
        });
        viewerStore.open(files, documentId(selectedDocument));
    };

    return (
        <>
            <DocumentLink to="#" onClick={handleClick}>
                {documents.length}
                <Icon name="documents" style={{ marginLeft: 8 }} />
            </DocumentLink>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        padding: "8px 16px",
                    },
                }}
            >
                <DocumentsDownloadAllButton
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadAllClick}
                    data-id="download_all"
                    fullWidth
                >
                    {t("results.accountSheet.documents.downloadAll")}
                </DocumentsDownloadAllButton>
                <DocumentsList>
                    {documents.map(document => {
                        return (
                            <DocumentsListItem key={documentId(document)}>
                                <DocumentLink to="#" onClick={ev => handleDownloadClick(ev, document)}>
                                    {document.name}
                                </DocumentLink>
                                <Icon
                                    name="open"
                                    onClick={() => {
                                        handleOpenClick(document);
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                            </DocumentsListItem>
                        );
                    })}
                </DocumentsList>
            </Popover>
        </>
    );
};
const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

interface OffsettingAccountsProps {
    financialAccountancyId: string;
    account: Account;
    accountTransaction: EnhancedAccountTransaction;
    offsettingAccounts: EnhancedAccountTransaction["offsettingAccounts"];
}

const OffsettingAccounts = ({
    financialAccountancyId,
    account,
    accountTransaction,
    offsettingAccounts,
}: OffsettingAccountsProps) => {
    if (!offsettingAccounts?.length) {
        return null;
    }

    const firstRendered = (
        <OffsettingAccountLink
            financialAccountancyId={financialAccountancyId}
            account={account}
            accountTransaction={accountTransaction}
            offsettingAccount={offsettingAccounts[0]}
        />
    );
    if (offsettingAccounts.length === 1) {
        return firstRendered;
    }
    if (offsettingAccounts.length === 2) {
        return (
            <FlexColumn>
                {firstRendered}
                <OffsettingAccountLink
                    financialAccountancyId={financialAccountancyId}
                    account={account}
                    accountTransaction={accountTransaction}
                    offsettingAccount={offsettingAccounts[1]}
                />
            </FlexColumn>
        );
    }

    return (
        <FlexColumn>
            {firstRendered}
            <MoreOffsettingAccounts
                financialAccountancyId={financialAccountancyId}
                account={account}
                accountTransaction={accountTransaction}
                offsettingAccounts={offsettingAccounts}
            />
        </FlexColumn>
    );
};

const MoreOffsettingAccountsList = styled.ul`
    margin: 8px 16px;
    list-style: none;
    text-align: right;
`;

interface MoreOffsettingAccountsProps extends OffsettingAccountsProps {}

const MoreOffsettingAccounts = ({
    financialAccountancyId,
    account,
    accountTransaction,
    offsettingAccounts,
}: MoreOffsettingAccountsProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setAnchorEl(ev.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const count = offsettingAccounts.length - 1; // the first one is already displayed

    return (
        <div>
            <DottedLink to="#" onClick={handleClick}>
                {t("results.accountSheet.offsettingAccounts.more", { count })}
            </DottedLink>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MoreOffsettingAccountsList>
                    {offsettingAccounts.slice(1).map(offsettingAccount => {
                        return (
                            <li key={offsettingAccount.number}>
                                <OffsettingAccountLink
                                    financialAccountancyId={financialAccountancyId}
                                    account={account}
                                    accountTransaction={accountTransaction}
                                    offsettingAccount={offsettingAccount}
                                />
                            </li>
                        );
                    })}
                </MoreOffsettingAccountsList>
            </Popover>
        </div>
    );
};

export interface ResultsAccountSheetNavigationState {
    type: "accountSheet";
    location: Location;
    account: Account;
    documentId: number;
    doNotScroll: true;
}

interface OffsettingAccountLinkProps {
    financialAccountancyId: string;
    account: Account;
    accountTransaction: EnhancedAccountTransaction;
    offsettingAccount: EnhancedAccountTransaction["offsettingAccounts"][number];
}

const OffsettingAccountLink = ({
    financialAccountancyId,
    account,
    accountTransaction,
    offsettingAccount,
}: OffsettingAccountLinkProps) => {
    const location = useLocation();
    const pathname = withParams(ResultsRoutes.ACCOUNT_SHEET, {
        financialAccountancyId,
        accountNr: offsettingAccount.number,
    });
    const state: ResultsAccountSheetNavigationState = {
        type: "accountSheet",
        location,
        account,
        documentId: accountTransaction.documentId,
        doNotScroll: true, // see App.tsx
    };
    return (
        <Tooltip title={offsettingAccount.name}>
            <DottedLink to={{ pathname, state }}>{formatAccountNumber(offsettingAccount.number)}</DottedLink>
        </Tooltip>
    );
};
