import { Checkbox, IconButton, Link, TableRow } from "@material-ui/core";
import compact from "lodash/compact";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { CompanyUsersToRelease, UserPermissions } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { TableStore, useInMemoryTableStore } from "../../stores/TableStore";
import { getNumPermissions, getPermissionChanges, getRoleString, getRolesString } from "../../util/permissionHelpers";
import { Comparer, compare } from "../../util/sort";
import { getFullName } from "../../util/user";
import { useSuccessDialog } from "../hooks/useSuccessDialog";
import { FormattedMessage } from "../ui/FormattedMessage";
import {
    TableLabel,
    TableLabelSmall,
    TableRowButton,
    TpaExpandedTableRowContainer,
    TpaTable,
    TpaTableCell,
    TpaTableContainer,
} from "../ui/Primitives";
import { ITableHeaderConfig, TableHeader } from "../ui/TableHeader";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

export type UserPermissionsAndCompany = UserPermissions & { uniqueId: string; company: { id: string; name: string } };

const waitingUsersTableStore = new TableStore<UserPermissionsAndCompany>(
    {
        orderBy: "lastName",
        orderDir: "desc",
        limit: Number.MAX_SAFE_INTEGER,
    },
    "WaitingUsers",
);

export const useWaitingUserTableReleaseOrReject = (onReleaseOrReject: () => Promise<void>) => {
    const releaseSuccessDialog = useSuccessDialog({
        title: t("userManagement.successDialog.releaseExistingUser.title"),
    });
    const rejectSuccessDialog = useSuccessDialog({
        title: t("userManagement.successDialog.rejectUser.title"),
    });
    const releaseOrRejectUsers = async (users: UserPermissionsAndCompany[], reject?: boolean) => {
        if (users.length === 0) {
            return;
        }

        try {
            generalStore.isLoading = true;
            for (const user of users) {
                if (reject === true) {
                    await API.rejectUserPermissions(user.company.id, user.id);
                } else {
                    await API.releaseUserPermissions(user.company.id, user.id);
                }
            }

            if (reject) {
                rejectSuccessDialog.openDialog();
            } else {
                const uninvitedUsers = users.filter(user => !user.confirmedAt);
                const someUserNeedsInvite = uninvitedUsers.length > 0;

                if (someUserNeedsInvite) {
                    releaseSuccessDialog.setTitle(t("userManagement.successDialog.releaseNewUser.title"));
                } else {
                    releaseSuccessDialog.setTitle(t("userManagement.successDialog.releaseExistingUser.title"));
                }

                if (users.length === 1) {
                    const user = users.find(userItem => userItem.id === users[0].id);

                    if (someUserNeedsInvite) {
                        releaseSuccessDialog.setMessage(
                            <FormattedMessage
                                id="userManagement.successDialog.newUser.superAdmin.single.message"
                                values={{ userName: getFullName(user) }}
                            />,
                        );
                    } else {
                        releaseSuccessDialog.setMessage(null);
                    }
                } else {
                    releaseSuccessDialog.setMessage(null);
                }

                releaseSuccessDialog.openDialog();
            }
        } catch (err) {
            generalStore.setError(t("error.general"), err);
        } finally {
            generalStore.isLoading = false;

            // reload table states (also in the error case, as some users might have been released or rejected anyway)
            await onReleaseOrReject();
        }
    };
    const releaseUsers = (users: UserPermissionsAndCompany[]) => {
        releaseOrRejectUsers(users, false);
    };
    const rejectUsers = (users: UserPermissionsAndCompany[]) => {
        releaseOrRejectUsers(users, true);
    };
    return {
        releaseUsers,
        rejectUsers,
        rejectSuccessDialog,
        releaseSuccessDialog,
    };
};

const searchFn = (user: UserPermissionsAndCompany, search: string) => getFullName(user).toLowerCase().includes(search);
const sortComparer: Comparer<UserPermissionsAndCompany> | undefined = {
    lastName: (u1, u2) => compare(getFullName(u1), getFullName(u2)),
    company: (u1, u2) => compare(u1.company.name, u2.company.name),
};

const useWaitingUserTableStore = (
    tableStore: TableStore<UserPermissionsAndCompany>,
    users: UserPermissionsAndCompany[],
) => {
    useInMemoryTableStore({
        tableStore,
        items: users,
        searchFn,
        sortComparer,
    });
};

export const useWaitingUserTable = (companyId: string, companyName: string, reloadUsers: () => Promise<void>) => {
    const [allUsers, setAllUsers] = React.useState<UserPermissionsAndCompany[]>([]);

    const tableStore = waitingUsersTableStore;

    useWaitingUserTableStore(tableStore, allUsers);

    const loadWaitingUsers = React.useCallback(async () => {
        try {
            generalStore.isLoading = true;
            await companiesStore.loadWaitingUsers();

            tableStore.selectedItems.clear();

            const company = { id: companyId, name: companyName };
            const waitingUsers = companiesStore
                .getWaitingUsers(companyId)
                .map((u): UserPermissionsAndCompany => ({ ...u, uniqueId: u.id + "/" + company.id, company }));
            setAllUsers(waitingUsers);
        } catch (err) {
            generalStore.setError(t("error.loadUsers"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, companyName, tableStore]);

    React.useEffect(() => {
        loadWaitingUsers();
    }, [loadWaitingUsers]);

    const { releaseUsers, rejectUsers, rejectSuccessDialog, releaseSuccessDialog } = useWaitingUserTableReleaseOrReject(
        async () => {
            await loadWaitingUsers();
            await reloadUsers();
        },
    );

    return {
        tableStore,
        allUsers,
        showCompany: false,
        releaseUsers,
        rejectUsers,
        rejectSuccessDialog,
        releaseSuccessDialog,
    };
};

const companiesWaitingUsersTableStore = new TableStore<UserPermissionsAndCompany>(
    {
        orderBy: "lastName",
        orderDir: "desc",
        limit: Number.MAX_SAFE_INTEGER,
    },
    "CompaniesWaitingUsers",
);

export const useCompaniesWaitingUsersTable = (
    waitingUsers: CompanyUsersToRelease[],
    onReleaseOrReject: () => Promise<void>,
): WaitingUserTableConfig => {
    const allUsers = React.useMemo(() => {
        const users: UserPermissionsAndCompany[] = [];
        waitingUsers.forEach(c => {
            const company = { id: c.id, name: c.name };
            c.users.forEach(u => {
                users.push({ ...u.permissions, uniqueId: u.permissions.id + "/" + company.id, company });
            });
        });
        return users;
    }, [waitingUsers]);

    const tableStore = companiesWaitingUsersTableStore;

    useWaitingUserTableStore(tableStore, allUsers);

    const { releaseUsers, rejectUsers, rejectSuccessDialog, releaseSuccessDialog } =
        useWaitingUserTableReleaseOrReject(onReleaseOrReject);

    return {
        tableStore,
        allUsers,
        showCompany: true,
        releaseUsers,
        rejectUsers,
        rejectSuccessDialog,
        releaseSuccessDialog,
    };
};

type WaitingUserTableConfig = ReturnType<typeof useWaitingUserTable>;

export const PermissionRow = ({ children }: { children: React.ReactNode }) => (
    <TableRow style={{ height: 32 }}>{children}</TableRow>
);

interface WaitingUserRowProps {
    index: number;
    user: UserPermissionsAndCompany;
    config: WaitingUserTableConfig;
    headerFields: ITableHeaderConfig[];
    onRelease: (user: UserPermissionsAndCompany) => void;
    onReject: (user: UserPermissionsAndCompany) => void;
    onEdit: (user: UserPermissionsAndCompany) => void;
}

const WaitingUserRow = observer(function WaitingUserRow(props: WaitingUserRowProps) {
    const { index, user, config, headerFields, onRelease, onReject, onEdit } = props;
    const [expanded, setExpanded] = React.useState(false);

    const hasRoleChange = user.changes?.roles && user.changes.roles.length > 0;
    const numChanges = getNumPermissions(user.changes) + (hasRoleChange ? 1 : 0);

    // Needed for rounding the last cell when expanded
    let currentRow = 0;
    const getBorderRadius = () => {
        currentRow++;
        return currentRow === numChanges ? "0 0 0 4px" : 0;
    };

    const permissionChanges = getPermissionChanges(user);

    return (
        <TpaExpandedTableRowContainer>
            <TableRow key={user.uniqueId}>
                <TpaTableCell
                    padding="checkbox"
                    style={{
                        borderLeft: `6px solid ${customColors.orangeOpen}`,
                        borderRadius: `4px 0 0 ${expanded ? 0 : "4px"}`,
                    }}
                >
                    <Checkbox
                        data-id={`user_checkbox_${index}`}
                        onChange={(event, checked) => {
                            config.tableStore.toggleSelection(user);
                        }}
                        color="primary"
                        checked={config.tableStore.isSelected(user)}
                        style={{ marginLeft: -6 }}
                    />
                </TpaTableCell>

                {headerFields.map(({ column }) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let label: any = user[column as keyof UserPermissions];
                    let tooltipLabel: string | undefined = undefined;

                    const rowProps: Partial<React.ComponentProps<typeof TpaTableCell>> = {
                        onClick:
                            column !== "expand"
                                ? () => {
                                      onEdit(user);
                                  }
                                : undefined,
                        style: column !== "expand" ? { cursor: "pointer" } : undefined,
                    };

                    if (column === "actions") {
                        const currentUserNoRole = user.roles.length === 0 || user.roles.includes("none");
                        const changedUserNoRole =
                            user.changes?.roles.length === 0 || user.changes?.roles.includes("none");

                        return (
                            <TpaTableCell
                                key={column}
                                style={{
                                    textAlign: "right",
                                    width: 1 /* width 1 limits to content */,
                                    whiteSpace: "nowrap",
                                    padding: 0,
                                }}
                            >
                                <TableRowButton
                                    color="primary"
                                    onClick={() => {
                                        onRelease(user);
                                    }}
                                    style={{
                                        whiteSpace: "nowrap",
                                        textTransform: "uppercase",
                                        padding: "5px 16px 3px 16px",
                                    }}
                                    // TPAPORTAL-2378: We disable if new user has no role, so no role before and no role afterwards
                                    disabled={currentUserNoRole && changedUserNoRole}
                                >
                                    {user.invitedAt && user.confirmedAt
                                        ? t("config.users.release")
                                        : t("config.users.releaseAndInvite")}
                                </TableRowButton>
                                <Link
                                    component="button"
                                    underline="always"
                                    color="inherit"
                                    onClick={() => {
                                        onReject(user);
                                    }}
                                    style={{
                                        fontSize: 10,
                                        marginRight: 16,
                                        textTransform: "lowercase",
                                        fontWeight: 500,
                                    }}
                                >
                                    {t("config.users.reject")}
                                </Link>
                            </TpaTableCell>
                        );
                    } else if (column === "lastName") {
                        label = getFullName(user);
                        tooltipLabel = user.username;
                    } else if (column === "changes") {
                        label = numChanges;
                    } else if (column === "role") {
                        label =
                            user.roles.length === 0
                                ? getRoleString({ role: "none" })
                                : getRolesString({ roles: user.roles });
                    } else if (column === "expand") {
                        return (
                            <TpaTableCell key={column} style={{ width: 1 /* width 1 limits to content */ }}>
                                <IconButton
                                    onClick={() => {
                                        setExpanded(!expanded);
                                    }}
                                >
                                    <Icon name={expanded ? "chevronUp" : "chevronDown"} />
                                </IconButton>
                            </TpaTableCell>
                        );
                    } else if (column === "company") {
                        label = user.company.name;
                        if (rowProps.style) {
                            rowProps.style.maxWidth = 100;
                        }
                    }

                    return (
                        <TpaTableCell key={column} {...rowProps}>
                            <TableLabel tooltip={tooltipLabel}>{label}</TableLabel>
                        </TpaTableCell>
                    );
                })}
            </TableRow>
            {expanded && (
                <>
                    {permissionChanges.map((permissionChange, index) => (
                        <PermissionRow key={index}>
                            <TpaTableCell
                                style={{
                                    borderLeft: `6px solid ${customColors.orangeOpen}`,
                                    borderRadius: getBorderRadius(),
                                }}
                            />
                            <TpaTableCell />
                            <TpaTableCell>
                                {permissionChange.name && <TableLabelSmall>{permissionChange.name}</TableLabelSmall>}
                            </TpaTableCell>
                            <TpaTableCell colSpan={4}>
                                <TableLabelSmall style={{ maxWidth: "none" }}>
                                    {permissionChange.change}
                                </TableLabelSmall>
                            </TpaTableCell>
                        </PermissionRow>
                    ))}
                </>
            )}
        </TpaExpandedTableRowContainer>
    );
});

interface WaitingUserTableProps {
    config: WaitingUserTableConfig;
    onEdit: (user: UserPermissionsAndCompany) => void;
}

export const WaitingUserTable = observer(function WaitingUserTable({ config, onEdit }: WaitingUserTableProps) {
    const handleClickReleaseAndInviteUser = (user: UserPermissionsAndCompany) => {
        config.releaseUsers([user]);
    };

    const handleClickRejectUser = (user: UserPermissionsAndCompany) => {
        config.rejectUsers([user]);
    };

    const headerFields = compact<ITableHeaderConfig>([
        { column: "lastName", label: "table.label.username" },
        config.showCompany ? { column: "company", label: "table.label.companyName" } : null,
        { column: "role", label: "table.label.role", sort: false },
        { column: "changes", label: "table.label.changes", sort: false },
        { column: "actions" },
        { column: "expand" },
    ]);

    const isEmpty = config.tableStore.items.length === 0 && !config.tableStore.search;
    if (isEmpty) {
        return null;
    }

    return (
        <TpaTableContainer>
            <TpaTable
            // style={{
            //     borderCollapse: "collapse", // need collapse for tr border to work
            // }}
            >
                <TableHeader
                    allowMultiSelectAlways
                    headerFields={headerFields}
                    tableStore={config.tableStore}
                    select={config.tableStore}
                />
                {config.tableStore.items.map((user, index) => (
                    <WaitingUserRow
                        key={user.uniqueId}
                        index={index}
                        user={user}
                        config={config}
                        headerFields={headerFields}
                        onRelease={handleClickReleaseAndInviteUser}
                        onReject={handleClickRejectUser}
                        onEdit={onEdit}
                    />
                ))}
            </TpaTable>
        </TpaTableContainer>
    );
});
