import { Route, Switch } from "react-router-dom";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { KpisSvsAccountTransactionsSite } from "../sites/KpisSvsAccountTransactionsSite";
import { KpisTaxAccountTransactionsSite } from "../sites/KpisTaxAccountTransactionsSite";
import { KpisUnpaidAccountsInvoicesSite } from "../sites/KpisUnpaidAccountsInvoicesSite";
import { KpisRoutes } from "./KpisRoutes";

export const KpisRouter = () => (
    <Switch>
        <Route exact path={KpisRoutes.TAX_ACCOUNT.TRANSACTIONS} component={KpisTaxAccountTransactionsSite} />
        <Route exact path={KpisRoutes.SVS_ACCOUNT.TRANSACTIONS} component={KpisSvsAccountTransactionsSite} />
        <Route exact path={KpisRoutes.UNPAID_ACCOUNTS.INVOICES} component={KpisUnpaidAccountsInvoicesSite} />
        <Route component={NotFoundSite} />
    </Switch>
);
