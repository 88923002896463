import { t } from "../i18n/util";
import { Company, DocumentReleaser } from "../network/APITypes";
import { periodToString } from "../stores/ModuleStore";
import { IBasicPeriod, Module } from "../types/models";
import { brToLineBreaks } from "./helpers";

/**
 * Describes a report that is used in some of the functions in here.
 *
 * This can either be a `Report` directly, or a custom representation of it (i.e. in `DocsListSite.tsx`).
 */
export interface ReportLike {
    needsRelease: boolean;
    released: boolean;
    releasers?: DocumentReleaser[] | null | undefined;
}

export function getRequiredReportReleasers(company: Company, module: Module) {
    if (module === "accounting") {
        return company.requiredAccountingReportReleasers ?? 1;
    } else if (module === "hr") {
        return company.requiredHRReportReleasers ?? 1;
    }
    return 0;
}

export function allReportReleasersDone(releasers: DocumentReleaser[] | null | undefined): boolean {
    return releasers ? releasers.every(s => s.releasedAt) : true;
}

export function isReportReleased(report: ReportLike): boolean {
    if (report.released) {
        return true;
    }

    // check if all report releasers have been done
    return allReportReleasersDone(report.releasers);
}

export function getReleaserForUser(
    releasers: DocumentReleaser[] | null | undefined,
    userId: string,
): DocumentReleaser | null {
    return releasers?.find(s => s.user && s.user.id === userId) ?? null;
}

export function hasUserReleasedReport(releasers: DocumentReleaser[] | null | undefined, userId: string): boolean {
    const releaser = getReleaserForUser(releasers, userId);
    return !!releaser && !!releaser.releasedAt;
}

/** Creates the placeholder for the comment field during the report upload */
export function getCommentPlaceholder({ period, signature }: { period: IBasicPeriod | undefined; signature: string }) {
    const salutation = t("screen.overview.salutation.general");
    const raw = t("screen.accounting.overview.reports", {
        br: "\n",
        salutation,
        period: periodToString(period),
        signature,
    });

    // replace <br /> with \n
    const withLineBreaks = brToLineBreaks(raw);

    // remove a leading salutation and line breaks because it is hard coded, e.g. in `ModuleReportCard.tsx`
    return withLineBreaks.replace(`${salutation}, \n \n `, "");
}
