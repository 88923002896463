import { Button, IconButton, TableBody, Tooltip } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import compact from "lodash/compact";
import sum from "lodash/sum";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { GLOBAL_FEATURES } from "../../features";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetCompaniesResponseItem } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { coordinator } from "../../stores/Coordinator";
import { generalStore } from "../../stores/GeneralStore";
import { useTableStore } from "../../stores/TableStore";
import { formatDate, getCountryName } from "../../util/helpers";
import { pushRoute } from "../app/router/history";
import { Routes } from "../app/router/Routes";
import { usePendingMicrosoftActions } from "../hooks/usePendingMicrosoftActions";
import {
    TableLabel,
    TableRowButton,
    TpaBadge,
    TpaTable,
    TpaTableCell,
    TpaTableContainer,
    TpaTableRow,
} from "../ui/Primitives";
import { SiteContent } from "../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../ui/TableHeader";
import { TableSearchBar } from "../ui/TableSearchBar";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { EmptyState } from "./EmptyState";
import { FallbackImage } from "./FallbackImage";
import { FormattedMessage } from "./FormattedMessage";

const StyledRemoveFromFavoritesButton = styled(IconButton)`
    color: #ffbb00;
    padding: 6px;
`;
const StyledAddToFavoritesButton = styled(IconButton)`
    padding: 6px;
    & > * {
        opacity: 0.3;
    }
`;

export const CompanyOverview = observer(function CompanyOverview() {
    const tableStore = useTableStore("CompanyOverview", {
        // CONNECT-17: sort by user favorites (which also sorts by name)
        orderBy: "favorite",
        orderDir: "asc",
    });

    React.useEffect(() => {
        companiesStore.clearSelection();
    }, []);

    const [companies, setCompanies] = React.useState<GetCompaniesResponseItem[]>([]);

    const { isCountryStale } = usePendingMicrosoftActions();

    const handleRowClick = async (companyId: string) => {
        const targetLocation = Routes.COCKPIT;
        try {
            await coordinator.selectCompanyById(companyId);
            pushRoute(targetLocation);
        } catch (err) {
            generalStore.setError(t("error.general"), err);
        }
    };

    const { tableParams } = tableStore;
    const loadCompanies = React.useCallback(
        async (noCache?: boolean) => {
            generalStore.isLoading = true;
            try {
                const response = await API.getCompanies(
                    { ...tableParams, logoImage: true, includeKnoedels: true },
                    noCache,
                );

                tableStore.totalCount = response.total;
                if (response.companies) {
                    setCompanies(response.companies);
                    companiesStore.totalUnreadNotifications = sum(
                        response.companies.map(c => c.knoedels?.unreadNotifications ?? 0),
                    );
                }
            } catch (err) {
                generalStore.setError(t("error.loadCompany"), err);
            } finally {
                generalStore.isLoading = false;
            }
        },
        [tableParams, tableStore],
    );

    React.useEffect(() => {
        loadCompanies();
    }, [loadCompanies]);

    const refreshCompanies = async () => {
        await authStore.loadUserInfo(true);
        await loadCompanies(true);
    };

    const handleAddToFavorites = async (ev: React.MouseEvent, company: GetCompaniesResponseItem) => {
        ev.preventDefault();
        ev.stopPropagation();

        generalStore.isLoading = true;
        try {
            await API.addCompanyToUserFavorites(company.id);
            setCompanies(companies => {
                return companies.map(c => (c.id === company.id ? { ...c, favorite: true } : c));
            });
        } catch (err) {
            generalStore.setError(t("error.addCompanyToUserFavorites"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };
    const handleRemoveFromFavorites = async (ev: React.MouseEvent, company: GetCompaniesResponseItem) => {
        ev.preventDefault();
        ev.stopPropagation();

        generalStore.isLoading = true;
        try {
            await API.removeCompanyFromUserFavorites(company.id);
            setCompanies(companies => {
                return companies.map(c => (c.id === company.id ? { ...c, favorite: false } : c));
            });
        } catch (err) {
            generalStore.setError(t("error.removeCompanyFromUserFavorites"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const headerFields: ITableHeaderConfig[] = compact([
        { column: "logo" },
        { column: "name", label: "table.label.companyName" },
        { column: "updatedAt", label: "table.label.updatedAt" },
        GLOBAL_FEATURES.tickets && { column: "ticketCount", label: "table.label.tickets", sort: false },
        GLOBAL_FEATURES.chat && { column: "messages", label: "table.label.messages", sort: false },
        GLOBAL_FEATURES.chat &&
            authStore.isTpa && { column: "teamMessages", label: "table.label.teamMessages", sort: false },
        {
            column: "favorite",
            label: (
                <span style={{ paddingLeft: 6, lineHeight: 0, opacity: 0.3 }}>
                    <StarIcon />
                </span>
            ),
        },
        { column: "enter" },
    ]);

    const tableBody = (
        <TableBody>
            {companies.map((company, index) => {
                return (
                    <TpaTableRow
                        key={company.id}
                        onClick={() => handleRowClick(company.id)}
                        role="button"
                        style={{ cursor: "pointer" }}
                        data-id={`company_${index}`}
                    >
                        {headerFields.map(field => {
                            let label;
                            if (field.column === "name") {
                                label = company.name;
                            } else if (field.column === "updatedAt") {
                                label = formatDate(company.updatedAt);
                            } else if (field.column === "logo") {
                                return (
                                    <TpaTableCell key={field.column}>
                                        <div
                                            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >
                                            {company.logoImage && (
                                                <FallbackImage
                                                    style={{
                                                        objectFit: "cover",
                                                        objectPosition: "center",
                                                        width: 40,
                                                        height: 40,
                                                        borderRadius: "100%",
                                                    }}
                                                    src={company.logoImage.imageUrl}
                                                    fallbackComponent={
                                                        <Icon
                                                            name="companyConfiguration"
                                                            style={{ color: customColors.greyDarkIcons }}
                                                        />
                                                    }
                                                />
                                            )}
                                            {!company.logoImage && (
                                                <Icon
                                                    name="companyConfiguration"
                                                    style={{ color: customColors.greyDarkIcons }}
                                                />
                                            )}
                                        </div>
                                    </TpaTableCell>
                                );
                            } else if (field.column === "enter") {
                                return (
                                    <TpaTableCell key={field.column} style={{ textAlign: "right" }}>
                                        <TableRowButton color="primary">{t("button.enter")}</TableRowButton>
                                    </TpaTableCell>
                                );
                            } else if (field.column === "ticketCount") {
                                if (
                                    !company.countryCode ||
                                    !authStore.userInfo?.has_microsoft_login ||
                                    !isCountryStale(company.countryCode)
                                ) {
                                    const numTickets = company.knoedels?.tickets ?? 0;
                                    label = numTickets > 0 ? <TpaBadge>{numTickets}</TpaBadge> : null;
                                } else {
                                    label = (
                                        <Tooltip
                                            title={
                                                <FormattedMessage
                                                    id="companyOverview.unlockCountry.tooltip"
                                                    values={{ country: getCountryName(company.countryCode) }}
                                                />
                                            }
                                        >
                                            <TpaBadge style={{ backgroundColor: customColors.disabled }}>!</TpaBadge>
                                        </Tooltip>
                                    );
                                }
                            } else if (field.column === "messages") {
                                const numMessages = company.knoedels?.unreadChatMessages ?? 0;
                                label = numMessages > 0 ? <TpaBadge>{numMessages}</TpaBadge> : null;
                            } else if (field.column === "teamMessages") {
                                const numMessages = company.knoedels?.unreadTeamChatMessages ?? 0;
                                label = numMessages > 0 ? <TpaBadge>{numMessages}</TpaBadge> : null;
                            } else if (field.column === "favorite") {
                                let button;
                                if (company.favorite) {
                                    button = (
                                        <StyledRemoveFromFavoritesButton
                                            disableRipple
                                            onClick={ev => handleRemoveFromFavorites(ev, company)}
                                        >
                                            <StarIcon />
                                        </StyledRemoveFromFavoritesButton>
                                    );
                                } else {
                                    button = (
                                        <StyledAddToFavoritesButton
                                            disableRipple
                                            onClick={ev => handleAddToFavorites(ev, company)}
                                        >
                                            <StarBorderIcon />
                                        </StyledAddToFavoritesButton>
                                    );
                                }
                                return <TpaTableCell key={field.column}>{button}</TpaTableCell>;
                            }

                            return (
                                <TpaTableCell key={field.column}>
                                    {typeof label === "string" ? (
                                        <TableLabel style={{ maxWidth: 270 }}>{label}</TableLabel>
                                    ) : (
                                        label
                                    )}
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    const isEmpty = tableStore.getIsEmptyState(generalStore.isLoading);

    return (
        <>
            {isEmpty && (
                <EmptyState
                    title={t(
                        authStore.isTpa ? "companyOverview.emptyState.tpa.title" : "companyOverview.emptyState.title",
                    )}
                    message={t(
                        authStore.isTpa
                            ? "companyOverview.emptyState.tpa.message"
                            : "companyOverview.emptyState.message",
                    )}
                />
            )}
            {!isEmpty && (
                <SiteContent>
                    <div style={{ textAlign: "right", marginBottom: 8 }}>
                        <Button color="primary" onClick={refreshCompanies} endIcon={<RefreshIcon />}>
                            {t("common.refresh")}
                        </Button>
                    </div>
                    <TableSearchBar
                        label="search.caption.numCompanies"
                        placeholder="search.placeholder.companies"
                        search={tableStore.search}
                        totalCount={tableStore.totalCount}
                        onChangeSearch={tableStore.handleSearchChange}
                    />
                    <TpaTableContainer>
                        <TpaTable>
                            <TableHeader headerFields={headerFields} tableStore={tableStore} />
                            {tableBody}
                        </TpaTable>
                    </TpaTableContainer>

                    <tableStore.Pagination />
                </SiteContent>
            )}
            {tableStore.getIsNoResultState(generalStore.isLoading) && (
                <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
            )}
        </>
    );
});
