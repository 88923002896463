import { TextField } from "@material-ui/core";
import { useEffect, useMemo } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { apiClient } from "../../network/APIClient";
import { generalStore } from "../../stores/GeneralStore";
import { IArchiveDocumentId } from "../../types/models";
import { getTextCharset } from "../../util/mime";
import { useQueryParams } from "../hooks/useQueryParams";

export const Comment = ({
    value,
    onChange,
    placeholder,
    shrinkLabel,
}: {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    shrinkLabel?: boolean;
}) => {
    const query: {
        comment?: string;
        commentSource?: string;
        commentAdditionalSourceData?: string;
        commentArchive?: string;
        commentDocumentId?: string;
    } = useQueryParams();

    const archiveDocumentId = useMemo<IArchiveDocumentId | null>(() => {
        if (!query.commentArchive || !query.commentDocumentId) {
            return null;
        }
        return {
            source: query.commentSource ?? "TPA",
            additionalSourceData: query.commentAdditionalSourceData,
            archiveId: query.commentArchive,
            documentId: query.commentDocumentId,
        };
    }, [query.commentArchive, query.commentDocumentId, query.commentSource, query.commentAdditionalSourceData]);

    useEffect(() => {
        if (query.comment) {
            onChange(query.comment);
        }
    }, [onChange, query.comment]);

    useEffect(() => {
        let cancelled = false;

        const load = async (body: IArchiveDocumentId) => {
            try {
                const download = await API.downloadExternalDocument(body);
                if (cancelled) {
                    return;
                }
                const response = await apiClient.apiClient.performFetch(download.downloadUrl, {});
                if (cancelled) {
                    return;
                }
                let text;
                const mimeType = response.headers.get("content-type");
                const charset = mimeType ? getTextCharset(mimeType) : null;
                if (charset && charset !== "utf-8") {
                    // comment files in NTCS might have a different encoding, e.g. iso-8859-1.
                    // convert them to utf-8 before displaying them in our utf-8 page.
                    const buffer = await response.arrayBuffer();
                    if (cancelled) {
                        return;
                    }
                    text = new TextDecoder(charset).decode(buffer);
                } else {
                    text = await response.text();
                }

                onChange(text);
            } catch (error) {
                if (cancelled) {
                    return;
                }
                generalStore.setError(t("screen.advisor.uploads.upload.comment.downloadError"), error);
            }
        };

        if (archiveDocumentId) {
            load(archiveDocumentId);
        }

        return () => {
            cancelled = true;
        };
    }, [onChange, archiveDocumentId]);

    return (
        <TextField
            value={value}
            onChange={event => {
                onChange(event.target.value);
            }}
            variant="outlined"
            label={t("screen.advisor.uploads.upload.comment")}
            placeholder={placeholder}
            fullWidth
            multiline
            style={{ marginTop: 40 }}
            inputProps={{ style: { minHeight: 50 /* 3 lines */ } }}
            InputLabelProps={shrinkLabel ? { shrink: true } : undefined}
        />
    );
};
