import compact from "lodash/compact";
import uniqueId from "lodash/uniqueId";
import { action, observable } from "mobx";
import { getDefaultConfig } from "../components/cockpit/kpis/utils";
import { t } from "../i18n/util";
import { API } from "../network/API";
import {
    KPIConfiguration as APIKPIConfiguration,
    GetCompanyKPIsResponse,
    SvsAccount,
    TaxAccount,
    UnpaidAccounts,
} from "../network/APITypes";
import {
    KPIConfiguration,
    KPIConfigurationEbitBarChart,
    KPIConfigurationEbitValue,
    KPIConfigurationInsuranceCarrierBalance,
    KPIConfigurationMetric,
    KPIConfigurationRevenueBarChart,
    KPIConfigurationRevenueValue,
} from "../types/kpis";
import { ICompany } from "../types/models";
import { debug } from "../util/debug";
import { isModuleActive } from "../util/modules";
import { parsePermissions } from "../util/permissionParser";
import { isNever } from "../util/ts";
import { generalStore } from "./GeneralStore";

export class KPIStore {
    readonly company: ICompany;
    readonly permissions: ReturnType<typeof parsePermissions>;

    @observable.ref configurations: KPIConfiguration[] | null = null;
    @observable isLoadingConfigurations = false;

    @observable kpis = new Map<number, GetCompanyKPIsResponse | "loading">();

    @observable isLoadingTaxAccount = false;
    @observable.ref taxAccount: TaxAccount | null = null;

    @observable isLoadingSvsAccount = false;
    @observable.ref svsAccount: SvsAccount | null = null;

    @observable isLoadingUnpaidAccounts = false;
    @observable unpaidAccounts: UnpaidAccounts | null = null;

    constructor(company: ICompany, permissions: KPIStore["permissions"]) {
        this.company = company;
        this.permissions = permissions;
    }

    get companyId() {
        return this.company.id;
    }

    // permissions

    get hasCockpitKpi() {
        return !!this.company.hasCockpitKPI;
    }
    get canRead() {
        return this.hasCockpitKpi && this.permissions.hasGlobalActions("expenses:cockpit", ["read"]);
    }
    get canEdit() {
        return this.hasCockpitKpi && this.permissions.hasGlobalActions("expenses:cockpit", ["create", "delete"]);
    }

    get canPayTaxAccount() {
        return (
            isModuleActive(this.company, "accounting") &&
            !!this.permissions.hasGlobalActions("accounting:taxAccount:payment", ["create"])
        );
    }

    // actions

    @action async loadConfigurations() {
        if (this.isLoadingConfigurations || this.configurations) {
            return; // already loaded or loading
        }

        if (!this.canRead) {
            return;
        }

        try {
            this.isLoadingConfigurations = true;
            const response = await API.getKPIConfiguration(this.companyId);
            this.configurations = response.configurations
                ? fromAPIConfigurations(response.configurations)
                : defaultKPIConfigurations;
        } catch (error) {
            generalStore.setError(t("error.loadKpiConfigurations"), error);
            return null;
        } finally {
            this.isLoadingConfigurations = false;
        }
    }
    @action async saveConfigurations(configurationsToSave: KPIConfiguration[]) {
        if (!this.canEdit) {
            return;
        }

        try {
            const configurations = toAPIConfigurations(configurationsToSave);
            await API.putKPIConfiguration(this.companyId, { configurations });
        } catch (error) {
            generalStore.setError(t("error.saveKpiConfigurations"), error);
            throw error;
        }

        this.configurations = configurationsToSave;
    }

    @action async loadKpis({ months }: { months: number }) {
        if (this.kpis.has(months) || this.kpis.get(months) === "loading") {
            return; // already loaded or loading
        }

        if (!this.canRead) {
            return;
        }

        try {
            this.kpis.set(months, "loading");

            const result = await API.getKpis(this.companyId, { months });
            this.kpis.set(months, result);
        } catch (error) {
            // ignore
            this.kpis.delete(months);
        }
    }

    @action async loadTaxAccount() {
        if (this.isLoadingTaxAccount || this.taxAccount) {
            return; // already loaded or loading
        }

        if (!this.canRead) {
            return;
        }

        try {
            this.isLoadingTaxAccount = true;

            const result = await API.getTaxAccount(this.companyId);
            this.taxAccount = result.taxAccount;
        } catch (error) {
            // ignore
        } finally {
            this.isLoadingTaxAccount = false;
        }
    }

    @action async loadSvsAccount() {
        if (this.isLoadingSvsAccount || this.svsAccount) {
            return; // already loaded or loading
        }

        if (!this.canRead) {
            return;
        }

        try {
            this.isLoadingSvsAccount = true;

            const result = await API.getSvsAccount(this.companyId);
            this.svsAccount = result.svsAccount;
        } catch (error) {
            // ignore
        } finally {
            this.isLoadingSvsAccount = false;
        }
    }

    @action async loadUnpaidAccounts() {
        if (this.isLoadingUnpaidAccounts || this.unpaidAccounts) {
            return; // already loaded or loading
        }

        if (!this.canRead) {
            return;
        }

        try {
            this.isLoadingUnpaidAccounts = true;

            const result = await API.getUnpaidAccounts(this.companyId);
            this.unpaidAccounts = result.unpaidAccounts;
        } catch (error) {
            // ignore
        } finally {
            this.isLoadingUnpaidAccounts = false;
        }
    }
}

const toAPIConfigurations = (configurations: KPIConfiguration[]) => {
    return configurations.map<APIKPIConfiguration>(c => {
        return {
            metric: c.metric,
            settings: "settings" in c ? c.settings : undefined,
        };
    });
};

const fromAPIConfigurations = (configurations: APIKPIConfiguration[]) => {
    return compact(
        configurations.map<KPIConfiguration | null>(c => {
            const id = uniqueId();
            const metric = c.metric as KPIConfigurationMetric;
            switch (metric) {
                case KPIConfigurationMetric.ebitBarChart:
                    return { id, metric, settings: c.settings as KPIConfigurationEbitBarChart["settings"] };
                case KPIConfigurationMetric.ebitValue:
                    return { id, metric, settings: c.settings as KPIConfigurationEbitValue["settings"] };
                case KPIConfigurationMetric.insuranceCarrierBalance:
                    return { id, metric, settings: c.settings as KPIConfigurationInsuranceCarrierBalance["settings"] };
                case KPIConfigurationMetric.revenueBarChart:
                    return { id, metric, settings: c.settings as KPIConfigurationRevenueBarChart["settings"] };
                case KPIConfigurationMetric.revenueValue:
                    return { id, metric, settings: c.settings as KPIConfigurationRevenueValue["settings"] };
                case KPIConfigurationMetric.taxAccountBalance:
                    return { id, metric };
                case KPIConfigurationMetric.svsAccountBalance:
                    return { id, metric };
                case KPIConfigurationMetric.unpaidAccounts:
                    return { id, metric };
                default:
                    isNever(metric); // ensure we handle all cases
                    debug.error("Unknown KPI configuration", c);
                    return null;
            }
        }),
    );
};

const defaultKPIConfigurations: KPIConfiguration[] = [
    getDefaultConfig(KPIConfigurationMetric.revenueValue),
    getDefaultConfig(KPIConfigurationMetric.ebitValue),
    getDefaultConfig(KPIConfigurationMetric.taxAccountBalance),
    getDefaultConfig(KPIConfigurationMetric.svsAccountBalance),
    getDefaultConfig(KPIConfigurationMetric.unpaidAccounts),
    getDefaultConfig(KPIConfigurationMetric.revenueBarChart),
    getDefaultConfig(KPIConfigurationMetric.ebitBarChart),
];
